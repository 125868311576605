import { memo, useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
  EMAIL,
  LOCATION,
  TEL_NUMBER_1,
  TEL_NUMBER_2,
  TEL_NUMBER_SPACE_1,
  TEL_NUMBER_SPACE_2,
  TITLE,
} from "~/utils/constants/constants";
import contact from "~/assets/img/contact.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { SIGN_UP } from "~/utils/constants/pathUrl";

const Contact = () => {
  useLayoutEffect(() => {
    document.title = `Liên hệ | ${TITLE}`;
  }, []);

  return (
    <div className="contact-page container">
      <div className="content">
        <img className="contact-image" src={contact} alt="contact" />

        <div className="text-left">
          <h2 className="text-4xl text-blue-900 ">Liên hệ</h2>
          <p className="mt-5 text-lg">
            Quý Phụ Huynh, Học Viên liên hệ tìm gia sư giỏi vui lòng liên hệ
            (Trung tâm hỗ trợ 24/24)
          </p>

          <div className="item">
            <div className="icon bg-blue-900">
              <FontAwesomeIcon
                className="w-6 h-6 p-3 sm:p-5 sm:w-8 sm:h-8"
                icon={faLocationDot}
                color="white"
              />
            </div>

            <div className="ml-4 sm:ml-8 text-base sm:text-xl">{LOCATION}</div>
          </div>

          <div className="item">
            <div className="icon bg-blue-900">
              <FontAwesomeIcon
                className="w-6 h-6 p-3 sm:p-5 sm:w-8 sm:h-8"
                icon={faPhone}
                color="white"
              />
            </div>

            <div className="ml-4 sm:ml-8 text-lg sm:text-2xl">
              <a className="text-blue-900" href={`tel:${TEL_NUMBER_1}`}>
                {TEL_NUMBER_SPACE_1}
              </a>
              <br />
              <a className="text-blue-900" href={`tel:${TEL_NUMBER_2}`}>
                {TEL_NUMBER_SPACE_2}
              </a>
            </div>
          </div>

          <div className="item">
            <div className="icon bg-blue-900">
              <FontAwesomeIcon
                className="w-6 h-6 p-3 sm:p-5 sm:w-8 sm:h-8"
                icon={faEnvelope}
                color="white"
              />
            </div>

            <div className="ml-4 sm:ml-8 text-lg sm:text-2xl">
              <a className="text-blue-900" href={`mailto:${EMAIL}`}>
                {EMAIL}
              </a>
            </div>
          </div>

          <div className="signup-button mt-8 md:mt-10">
            <Link
              className="text-lg bg-blue-900 px-3 py-2 rounded-lg text-white"
              to={`/${SIGN_UP}`}
            >
              + Đăng ký ngay
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Contact);
