import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import reduxThunk from "redux-thunk";
import studentsReducer from "~/pages/Tutors/redux/studentsSlice";
import filtersReducer from "~/components/FilterComponent/redux/filtersSlice";
import tutorsReducer from "~/pages/Students/redux/tutorsSlice";
import registerTutorReducer from "~/pages/SignUp/redux/reducer";
import loginTutorReducer from "~/pages/SignIn/redux/reducer";

const middleware = [reduxThunk];

const rootReducer = combineReducers({
  registerTutorState: registerTutorReducer,
  loginTutorState: loginTutorReducer,
  studentsState: studentsReducer,
  tutorsState: tutorsReducer,
  filtersState: filtersReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
);
