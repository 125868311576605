import { useLocation } from "react-router-dom";

import Arrow from "~/assets/svg/sign in/Arrow.svg";
import PrivacyPolicy from "~/assets/svg/sign in/Privacy policy-rafiki.svg";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import { SIGN_IN } from "~/utils/constants/pathUrl";

import "./styles.scss";

const SignIn = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="sign-in">
      <div className="sign-in-left">
        <img className="arrow" src={Arrow} alt="Arrow" />

        {pathname.includes(SIGN_IN) ? <Login /> : <ForgotPassword />}
      </div>
      <div className="sign-in-right">
        <div className="ilu">
          <img src={PrivacyPolicy} alt="PrivacyPolicy" />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
