import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import bcrypt from "bcryptjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FloatLabel from "~/components/FloatLabels/FloatLabel";
import Spinner from "~/components/Loading/Spinner";
import ReCapcha from "~/components/ReCapcha";
import UploadImages from "~/components/UploadImagesComponent";
import { uploadImagesToCloudinary } from "~/functions/uploadImagesToCloudinary";
import { useFocus, useThrottle, useTrapFocus } from "~/hooks";
import {
  editProfileAction,
  editProfileFail,
  editProfileStart,
} from "~/pages/Tutors/redux/actions";
import { loginTutor, tutorSelector } from "~/pages/Tutors/redux/selectors";
import { cityData, provinceData } from "~/utils/data/countryData";
import { lopHocList, monHocList, trinhDoList } from "~/utils/data/infoData";
import {
  validateDiaChiHienTai,
  validateEmail,
  validateGioiTinh,
  validateHoTen,
  validateKhuVucDay,
  validateLopDay,
  validateMonDay,
  validateNganhHoc,
  validateNgaySinh,
  validateNguyenQuan,
  validateQuanHuyen,
  validateSdt,
  validateSoCCCD,
  validateTrinhDo,
  validateTruongDaoTao,
  validateUuDiem,
} from "~/utils/validations/validationsInput";

import "./styles.scss";

const { TextArea } = Input;
const { Option } = Select;

const EditProfile = (props) => {
  const [quanHuyen, setQuanHuyen] = useState([]);
  const [avatarFileList, setAvatarFileList] = useState([]);
  const [IDCardFileList, setIDCardFileList] = useState([]);
  const [degreeFileList, setDegreeFileList] = useState([]);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isChangeImg, setIsChangeImg] = useState(false);
  const [isCheckRecaptcha, setIsCheckRecaptcha] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [tutors, setTutors] = useState({});
  const {
    sdt,
    matKhau,
    xacNhanMatKhau,
    hoTen,
    ngaySinh,
    gioiTinh,
    nguyenQuan,
    diaChiHienTai,
    soCCCD,
    email,
    truongDaoTao,
    nganhHoc,
    trinhDo,
    uuDiem,
    monDay,
    lopDay,
    tinhThanhDay,
    quanHuyenDay,
    anhDaiDien,
    anhBangCap,
    anhCCCD,
  } = tutors;

  const tutorLogin = useSelector(loginTutor);
  const user = useSelector(tutorSelector);
  const { loading } = user;

  useEffect(() => {
    if (tutorLogin) {
      setTutors(tutorLogin);
      form.setFieldsValue(tutorLogin);
    }
  }, [tutorLogin, form]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTutors((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s+/g, " ").trim(),
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const handleSelectChange = (name) => (value) => {
    setTutors((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProvinceChange = (value) => {
    if (value) {
      const provinceLabel = provinceData.find(
        (val) => val.value === value
      )?.label;
      setTutors((prevState) => ({
        ...prevState,
        tinhThanhDay: provinceLabel,
      }));
      setQuanHuyen(cityData[value]);
      form.setFieldsValue({
        tinhThanhDay: provinceLabel,
        quanHuyenDay: undefined,
      });
    } else {
      setTutors((prevState) => ({
        ...prevState,
        tinhThanhDay: null,
      }));
      setQuanHuyen(null);

      form.setFieldsValue({ quanHuyenDay: undefined });
    }
  };

  const handleCityChange = (arrayValue) => {
    if (arrayValue.length) {
      const arrayCityLabel = arrayValue.map(
        (value) => quanHuyen.find((el) => el.value === value)?.label
      );
      setTutors((prevState) => ({
        ...prevState,
        quanHuyenDay: arrayCityLabel,
      }));
    }
  };

  const uploadImgs = async (typePhoto, typeFileList) => {
    if (typeFileList.length) {
      return await uploadImagesToCloudinary(
        typeFileList,
        "tutors",
        typePhoto,
        sdt
      );
    }
    return null;
  };

  const handleCheckRecaptcha = (value) => {
    setIsCheckRecaptcha(true);
  };

  const onFinish = async (values) => {
    if (isCheckRecaptcha) {
      dispatch(editProfileStart());

      const hashedPassword = matKhau && bcrypt.hashSync(matKhau, 10);
      const anhDaiDienUrl = await uploadImgs("anhDaiDien", avatarFileList);
      const anhCCCDUrl = await uploadImgs("anhCCCD", IDCardFileList);
      const anhBangCapUrl = await uploadImgs("anhBangCap", degreeFileList);

      Promise.all([anhDaiDienUrl, anhCCCDUrl, anhBangCapUrl])
        .then((result) => {
          const tutor = {
            ...tutors,
            matKhau: hashedPassword || matKhau,
            anhDaiDien: result[0] || anhDaiDien,
            anhCCCD: result[1] || anhCCCD,
            anhBangCap: result[2] || anhBangCap,
          };

          dispatch(editProfileAction(tutor));
        })
        .catch((error) => dispatch(editProfileFail(error.message)));
    }
  };

  useFocus("sdt");
  useTrapFocus("update-tutors");

  return (
    <div className="update-tutors">
      <div className="container">
        <h1 className=" text-2xl my-3">Chỉnh sửa thông tin</h1>

        <Form
          id="update-tutors"
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <p className="text-base font-medium my-2">Thông tin tài khoản</p>

          <FloatLabel label="Số điện thoại" name="sdt" value={sdt}>
            <Form.Item name="sdt" rules={validateSdt}>
              <Input
                id="sdt"
                name="sdt"
                value={sdt}
                onChange={handleInputThrottled}
                maxLength={10}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Email" name="email" value={email}>
            <Form.Item name="email" rules={validateEmail}>
              <Input
                placeholder={email}
                value={email}
                name="email"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <p className="text-base font-medium my-2">Thông tin đăng ký</p>

          <FloatLabel label="Họ và tên" name="hoTen" value={hoTen}>
            <Form.Item name="hoTen" rules={validateHoTen}>
              <Input
                placeholder={hoTen}
                value={hoTen}
                name="hoTen"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <Row gutter={8}>
            <Col span={12}>
              <FloatLabel label="Ngày sinh" name="ngaySinh" value={ngaySinh}>
                <Form.Item
                  name="ngaySinh"
                  rules={validateNgaySinh}
                  valuePropName={ngaySinh}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    onChange={(_, dateString) => {
                      setTutors((prevState) => ({
                        ...prevState,
                        ngaySinh: dateString,
                      }));
                    }}
                    placeholder=""
                    value={moment(ngaySinh, "DD/MM/YYYY")}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col span={12}>
              <FloatLabel label="Giới tính" name="gioiTinh" value={gioiTinh}>
                <Form.Item name="gioiTinh" rules={validateGioiTinh}>
                  <Select
                    style={{ width: "100%" }}
                    name="gioiTinh"
                    onChange={handleSelectChange("gioiTinh")}
                    value={gioiTinh}
                  >
                    <Option value="Nam">Nam</Option>
                    <Option value="Nữ">Nữ</Option>
                    <Option value="Khác">Khác</Option>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>

          <FloatLabel label="Nguyên quán" name="nguyenQuan" value={nguyenQuan}>
            <Form.Item name="nguyenQuan" rules={validateNguyenQuan}>
              <Input
                placeholder={nguyenQuan}
                name="nguyenQuan"
                value={nguyenQuan}
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Địa chỉ hiện tại"
            name="diaChiHienTai"
            value={diaChiHienTai}
          >
            <Form.Item name="diaChiHienTai" rules={validateDiaChiHienTai}>
              <Input
                placeholder={diaChiHienTai}
                value={diaChiHienTai}
                name="diaChiHienTai"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Số CMND/CCCD" name="soCCCD" value={soCCCD}>
            <Form.Item name="soCCCD" rules={validateSoCCCD}>
              <Input
                placeholder={soCCCD}
                value={soCCCD}
                name="soCCCD"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Trường đào tạo"
            name="truongDaoTao"
            value={truongDaoTao}
          >
            <Form.Item name="truongDaoTao" rules={validateTruongDaoTao}>
              <Input
                placeholder={truongDaoTao}
                value={truongDaoTao}
                name="truongDaoTao"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <Row gutter={8}>
            <Col span={12}>
              <FloatLabel label="Ngành học" name="nganhHoc" value={nganhHoc}>
                <Form.Item name="nganhHoc" rules={validateNganhHoc}>
                  <Input
                    placeholder={nganhHoc}
                    value={nganhHoc}
                    name="nganhHoc"
                    onChange={handleInputThrottled}
                    allowClear
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col span={12}>
              <FloatLabel label="Hiện là" name="trinhDo" value={trinhDo}>
                <Form.Item name="trinhDo" rules={validateTrinhDo}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={handleSelectChange("trinhDo")}
                    value={trinhDo}
                  >
                    {trinhDoList.map((trinhDo) => (
                      <Option key={trinhDo} value={trinhDo}>
                        {trinhDo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>

          <FloatLabel label="Môn dạy" name="monDay" value={monDay}>
            <Form.Item name="monDay" rules={validateMonDay}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleSelectChange("monDay")}
                value={monDay}
              >
                {monHocList.map((monHoc) => (
                  <Option key={monHoc} value={monHoc}>
                    {monHoc}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Lớp dạy" name="lopDay" value={lopDay}>
            <Form.Item name="lopDay" rules={validateLopDay}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleSelectChange("lopDay")}
                value={lopDay}
              >
                {lopHocList.map((lop) => (
                  <Option key={lop} value={lop}>
                    {lop}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Khu vực dạy"
            name="tinhThanhDay"
            value={tinhThanhDay}
          >
            <Form.Item name="tinhThanhDay" rules={validateKhuVucDay}>
              <Select
                onChange={handleProvinceChange}
                name="tinhThanhDay"
                options={provinceData}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Chọn quận/huyện dạy"
            name="quanHuyenDay"
            value={quanHuyenDay}
          >
            <Form.Item name="quanHuyenDay" rules={validateQuanHuyen}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleCityChange}
                name="quanHuyenDay"
                options={quanHuyen}
                value={quanHuyenDay}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Ưu điểm" name="uuDiem" value={uuDiem}>
            <Form.Item name="uuDiem" rules={validateUuDiem}>
              <TextArea
                showCount
                maxLength={500}
                style={{ height: 120, resize: "none" }}
                name="uuDiem"
                onChange={handleInputThrottled}
                placeholder={uuDiem}
              />
            </Form.Item>
          </FloatLabel>

          <p className="text-base my-2">
            * Bấm nút phía dưới nếu thay đổi thông tin mật khẩu hoặc hình ảnh.
            Nếu không chỉnh sửa, xin vui lòng bấm đóng lại.
          </p>

          <Button
            className="mb-6 flex items-center"
            onClick={() => {
              setIsChangePassword(!isChangePassword);
              form.setFieldsValue({ matKhau: undefined });
            }}
          >
            Thay đổi mật khẩu
            {isChangePassword ? (
              <ArrowSmUpIcon className="h-4 w-4 ml-1" />
            ) : (
              <ArrowSmDownIcon className="h-4 w-4 ml-1" />
            )}
          </Button>

          {isChangePassword && (
            <>
              <FloatLabel label="Mật khẩu" name="matKhau" value={matKhau}>
                <Form.Item
                  name="matKhau"
                  rules={[
                    {
                      min: 6,
                      message: "Mật khẩu ít nhất 6 kí tự!",
                    },
                  ]}
                >
                  <Input.Password
                    value={matKhau}
                    name="matKhau"
                    onChange={handleInputThrottled}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </FloatLabel>

              <FloatLabel
                label="Xác nhận mật khẩu"
                name="xacNhanMatKhau"
                value={xacNhanMatKhau}
              >
                <Form.Item
                  name=""
                  dependencies={["matKhau"]}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const valueMatKhau = getFieldValue("matKhau");
                        if (!value && valueMatKhau) {
                          return Promise.reject(
                            new Error("Xác nhận mật khẩu!")
                          );
                        } else if (!value || valueMatKhau === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Mật khẩu chưa khớp!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    value={xacNhanMatKhau}
                    name="xacNhanMatKhau"
                    onChange={handleInputThrottled}
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </FloatLabel>
            </>
          )}

          <Button
            className="mb-6 flex items-center"
            onClick={() => {
              setIsChangeImg(!isChangeImg);
            }}
          >
            Thay đổi ảnh
            {isChangeImg ? (
              <ArrowSmUpIcon className="h-4 w-4 ml-1" />
            ) : (
              <ArrowSmDownIcon className="h-4 w-4 ml-1" />
            )}
          </Button>

          {sdt && hoTen && isChangeImg && (
            <>
              <UploadImages
                typePhoto="anhDaiDien"
                value={anhDaiDien}
                description="Ảnh đại diện (rõ mặt)"
                setImagesFileList={setAvatarFileList}
              />

              <UploadImages
                typePhoto="anhCCCD"
                value={anhCCCD}
                multiple={true}
                maxCount={2}
                description="Ảnh CCCD (2 mặt thẻ)"
                setImagesFileList={setIDCardFileList}
              />

              <UploadImages
                typePhoto="anhBangCap"
                value={anhBangCap}
                multiple={true}
                maxCount={4}
                description="Ảnh bằng cấp (tối đa 4 ảnh)"
                setImagesFileList={setDegreeFileList}
              />
            </>
          )}

          <ReCapcha
            isCheckRecaptcha={isCheckRecaptcha}
            handleCheckRecaptcha={handleCheckRecaptcha}
          />

          <Form.Item>
            <Button
              className="button-form mt-3"
              htmlType="submit"
              disabled={loading}
            >
              Chỉnh sửa
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EditProfile;
