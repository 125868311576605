import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import bcrypt from "bcryptjs";

import FloatLabel from "~/components/FloatLabels/FloatLabel";
import UploadImages from "~/components/UploadImagesComponent";
import { uploadImagesToCloudinary } from "~/functions/uploadImagesToCloudinary";
import { useFocus, useTrapFocus, useThrottle } from "~/hooks";
import { SIGN_UP } from "~/utils/constants/pathUrl";
import { cityData, provinceData } from "~/utils/data/countryData";
import { lopHocList, monHocList, trinhDoList } from "~/utils/data/infoData";
import {
  validateDiaChiHienTai,
  validateGioiTinh,
  validateHoTen,
  validateKhuVucDay,
  validateLopDay,
  validateMatKhau,
  validateMonDay,
  validateNganhHoc,
  validateNgaySinh,
  validateNguyenQuan,
  validateQuanHuyen,
  validateSoCCCD,
  validateTrinhDo,
  validateTruongDaoTao,
  validateUuDiem,
  validateXacNhanMatKhau,
} from "~/utils/validations/validationsInput";
import { tutorsSelector } from "~/pages/Students/redux/selectors";
import { getListTutors } from "~/pages/Students/redux/actions";
import Spinner from "~/components/Loading/Spinner";
import { registerFail, registerStart, signUpAction } from "../redux/actions";
import { TUTORS } from "~/utils/constants/constants";
import ReCapcha from "~/components/ReCapcha";
import "./styles.scss";

const { TextArea } = Input;
const { Option } = Select;

const initialValues = {
  sdt: "",
  matKhau: "",
  xacNhanMatKhau: "",
  hoTen: "",
  ngaySinh: "",
  gioiTinh: "",
  nguyenQuan: "Phường Tăng Nhơn Phú A, Quận 9, TPHCM",
  diaChiHienTai: "Số 12, đường Võ Thị Sáu, phường 3, Quận 3, TPHCM",
  soCCCD: "",
  email: "",
  truongDaoTao: "Đại học Sư phạm TPHCM",
  nganhHoc: "Sư phạm Toán",
  trinhDo: "",
  uuDiem:
    "Thông tin chi tiết về kinh nghiệm và thành tích bạn đạt được trong quá trình học tập và dạy kèm.",
  monDay: "",
  lopDay: "",
  tinhThanhDay: "",
  quanHuyenDay: [],
  anhDaiDien: [],
  anhBangCap: [],
  anhCCCD: [],
  nguoiDung: "giaSu",
  lopDangKy: [],
  lopDaNhan: [],
  xacNhan: false,
};

const SignUpTutors = (props) => {
  const [quanHuyen, setQuanHuyen] = useState([]);
  const [avatarFileList, setAvatarFileList] = useState([]);
  const [IDCardFileList, setIDCardFileList] = useState([]);
  const [degreeFileList, setDegreeFileList] = useState([]);
  const [isCheckRecaptcha, setIsCheckRecaptcha] = useState(undefined);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [tutors, setTutors] = useState(initialValues);
  const {
    sdt,
    matKhau,
    xacNhanMatKhau,
    hoTen,
    ngaySinh,
    gioiTinh,
    nguyenQuan,
    diaChiHienTai,
    soCCCD,
    email,
    truongDaoTao,
    nganhHoc,
    trinhDo,
    uuDiem,
    monDay,
    lopDay,
    tinhThanhDay,
    quanHuyenDay,
    anhDaiDien,
    anhBangCap,
    anhCCCD,
  } = tutors;

  const tutorList = useSelector(tutorsSelector);
  const user = useSelector((state) => state.registerTutorState);
  const { loading, error } = user;

  useEffect(() => {
    dispatch(getListTutors());

    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTutors((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s+/g, " ").trim(),
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const handleSelectChange = (name) => (value) => {
    setTutors((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProvinceChange = (value) => {
    if (value) {
      const provinceLabel = provinceData.find(
        (val) => val.value === value
      )?.label;
      setTutors((prevState) => ({
        ...prevState,
        tinhThanhDay: provinceLabel,
      }));
      setQuanHuyen(cityData[value]);
      form.setFieldsValue({
        tinhThanhDay: provinceLabel,
        quanHuyenDay: undefined,
      });
    } else {
      setTutors((prevState) => ({
        ...prevState,
        tinhThanhDay: null,
      }));
      setQuanHuyen(null);

      form.setFieldsValue({ quanHuyenDay: undefined });
    }
  };

  const handleCityChange = (arrayValue) => {
    if (arrayValue.length) {
      const arrayCityLabel = arrayValue.map(
        (value) => quanHuyen.find((el) => el.value === value)?.label
      );
      setTutors((prevState) => ({
        ...prevState,
        quanHuyenDay: arrayCityLabel,
      }));
    }
  };

  const uploadImgs = async (typePhoto, typeFileList) => {
    if (typeFileList.length) {
      return await uploadImagesToCloudinary(
        typeFileList,
        "tutors",
        typePhoto,
        sdt
      );
    }
    return;
  };

  const onFinish = async (values) => {
    if (isCheckRecaptcha) {
      dispatch(registerStart());

      delete tutors.xacNhanMatKhau;
      const hashedPassword = bcrypt.hashSync(matKhau, 10);
      const maGiaSu = (+tutorList[0].maGiaSu + 1).toString();
      const anhDaiDienUrl = await uploadImgs("anhDaiDien", avatarFileList);
      const anhCCCDUrl = await uploadImgs("anhCCCD", IDCardFileList);
      const anhBangCapUrl = await uploadImgs("anhBangCap", degreeFileList);

      Promise.all([anhDaiDienUrl, anhCCCDUrl, anhBangCapUrl])
        .then((result) => {
          const tutor = {
            ...tutors,
            maGiaSu: maGiaSu || "1",
            matKhau: hashedPassword,
            anhDaiDien: result[0],
            anhCCCD: result[1],
            anhBangCap: result[2],
          };

          dispatch(signUpAction(tutor, TUTORS));

          if (error) {
            return;
          } else {
            form.resetFields();
            dispatch(getListTutors());
          }
        })
        .catch((error) => dispatch(registerFail(error.message)));
    } else {
      setIsCheckRecaptcha(false);
    }
  };

  const handleCheckRecaptcha = (value) => {
    setIsCheckRecaptcha(true);
  };

  useFocus("sdt");
  useTrapFocus("sign-up-tutors");

  return (
    <div className="signup-tutors">
      <div className="container">
        <Link
          className="flex text-blue-900 w-24 hover:text-blue-900"
          to={`/${SIGN_UP}`}
        >
          <ArrowSmLeftIcon className="h-5 w-5 mr-1" />
          Quay lại
        </Link>
        <h1 className=" text-2xl my-3">Đăng ký gia sư mới</h1>

        <Form
          id="sign-up-tutors"
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <p className="text-base font-medium my-2">Thông tin tài khoản</p>

          <FloatLabel label="Số điện thoại" name="sdt" value={sdt}>
            <Form.Item
              name="sdt"
              rules={[
                {
                  min: 10,
                  max: 10,
                  pattern: new RegExp(/(0[2-9])+([0-9]{8})\b/),
                  message: "Số điện thoại chưa đúng!",
                },
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại!",
                },
                () => ({
                  validator(_, value) {
                    const isDuplicateSdt = tutorList.some(
                      (tutor) => tutor.sdt === value
                    );
                    if (!isDuplicateSdt) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Số điện thoại đã được sử dụng!")
                    );
                  },
                }),
              ]}
            >
              <Input
                id="sdt"
                placeholder={sdt}
                name="sdt"
                value={sdt}
                onChange={handleInputThrottled}
                maxLength={10}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Mật khẩu" name="matKhau" value={matKhau}>
            <Form.Item name="matKhau" rules={validateMatKhau}>
              <Input.Password
                value={matKhau}
                name="matKhau"
                onChange={handleInputThrottled}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Xác nhận mật khẩu"
            name="xacNhanMatKhau"
            value={xacNhanMatKhau}
          >
            <Form.Item
              name=""
              dependencies={["matKhau"]}
              rules={validateXacNhanMatKhau}
            >
              <Input.Password
                value={xacNhanMatKhau}
                name="xacNhanMatKhau"
                onChange={handleInputThrottled}
                autoComplete="off"
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Email" name="email" value={email}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "E-mail chưa đúng định dạng!",
                },
                {
                  required: true,
                  message: "Vui lòng nhập email!",
                },
                () => ({
                  validator(_, value) {
                    const isDuplicateEmail = tutorList.some(
                      (tutor) => tutor.email === value
                    );
                    if (!isDuplicateEmail) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Email đã được sử dụng!"));
                  },
                }),
              ]}
            >
              <Input
                placeholder={email}
                value={email}
                name="email"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <p className="text-base font-medium my-2">Thông tin đăng ký</p>

          <FloatLabel label="Họ và tên" name="hoTen" value={hoTen}>
            <Form.Item name="hoTen" rules={validateHoTen}>
              <Input
                placeholder={hoTen}
                value={hoTen}
                name="hoTen"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <Row gutter={8}>
            <Col span={12}>
              <FloatLabel label="Ngày sinh" name="ngaySinh" value={ngaySinh}>
                <Form.Item name="ngaySinh" rules={validateNgaySinh}>
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    onChange={(_, dateString) => {
                      setTutors((prevState) => ({
                        ...prevState,
                        ngaySinh: dateString,
                      }));
                    }}
                    placeholder=""
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col span={12}>
              <FloatLabel label="Giới tính" name="gioiTinh" value={gioiTinh}>
                <Form.Item name="gioiTinh" rules={validateGioiTinh}>
                  <Select
                    style={{ width: "100%" }}
                    name="gioiTinh"
                    onChange={handleSelectChange("gioiTinh")}
                    value={gioiTinh}
                  >
                    <Option value="Nam">Nam</Option>
                    <Option value="Nữ">Nữ</Option>
                    <Option value="Khác">Khác</Option>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>

          <FloatLabel label="Nguyên quán" name="nguyenQuan" value={nguyenQuan}>
            <Form.Item name="nguyenQuan" rules={validateNguyenQuan}>
              <Input
                placeholder={nguyenQuan}
                name="nguyenQuan"
                value={nguyenQuan}
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Địa chỉ hiện tại"
            name="diaChiHienTai"
            value={diaChiHienTai}
          >
            <Form.Item name="diaChiHienTai" rules={validateDiaChiHienTai}>
              <Input
                placeholder={diaChiHienTai}
                value={diaChiHienTai}
                name="diaChiHienTai"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Số CMND/CCCD" name="soCCCD" value={soCCCD}>
            <Form.Item name="soCCCD" rules={validateSoCCCD}>
              <Input
                placeholder={soCCCD}
                value={soCCCD}
                name="soCCCD"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Trường đào tạo"
            name="truongDaoTao"
            value={truongDaoTao}
          >
            <Form.Item name="truongDaoTao" rules={validateTruongDaoTao}>
              <Input
                placeholder={truongDaoTao}
                value={truongDaoTao}
                name="truongDaoTao"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <Row gutter={8}>
            <Col span={12}>
              <FloatLabel label="Ngành học" name="nganhHoc" value={nganhHoc}>
                <Form.Item name="nganhHoc" rules={validateNganhHoc}>
                  <Input
                    placeholder={nganhHoc}
                    value={nganhHoc}
                    name="nganhHoc"
                    onChange={handleInputThrottled}
                    allowClear
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col span={12}>
              <FloatLabel label="Hiện là" name="trinhDo" value={trinhDo}>
                <Form.Item name="trinhDo" rules={validateTrinhDo}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={handleSelectChange("trinhDo")}
                    value={trinhDo}
                  >
                    {trinhDoList.map((trinhDo) => (
                      <Option key={trinhDo} value={trinhDo}>
                        {trinhDo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>

          <FloatLabel label="Môn dạy" name="monDay" value={monDay}>
            <Form.Item name="monDay" rules={validateMonDay}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleSelectChange("monDay")}
                value={monDay}
              >
                {monHocList.map((monHoc) => (
                  <Option key={monHoc} value={monHoc}>
                    {monHoc}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Lớp dạy" name="lopDay" value={lopDay}>
            <Form.Item name="lopDay" rules={validateLopDay}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleSelectChange("lopDay")}
                value={lopDay}
              >
                {lopHocList.map((lop) => (
                  <Option key={lop} value={lop}>
                    {lop}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Khu vực dạy"
            name="tinhThanhDay"
            value={tinhThanhDay}
          >
            <Form.Item name="tinhThanhDay" rules={validateKhuVucDay}>
              <Select
                onChange={handleProvinceChange}
                name="tinhThanhDay"
                options={provinceData}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel
            label="Chọn quận/huyện dạy"
            name="quanHuyenDay"
            value={quanHuyenDay}
          >
            <Form.Item name="quanHuyenDay" rules={validateQuanHuyen}>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                onChange={handleCityChange}
                name="quanHuyenDay"
                options={quanHuyen}
                value={quanHuyenDay}
                allowClear
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Ưu điểm" name="uuDiem" value={uuDiem}>
            <Form.Item name="uuDiem" rules={validateUuDiem}>
              <TextArea
                showCount
                maxLength={500}
                style={{ height: 120, resize: "none" }}
                name="uuDiem"
                onChange={handleInputThrottled}
                placeholder={uuDiem}
              />
            </Form.Item>
          </FloatLabel>

          {sdt && hoTen && (
            <>
              <UploadImages
                typePhoto="anhDaiDien"
                value={anhDaiDien}
                description="Ảnh đại diện (rõ mặt)"
                setImagesFileList={setAvatarFileList}
              />

              <UploadImages
                typePhoto="anhCCCD"
                value={anhCCCD}
                multiple={true}
                maxCount={2}
                description="Ảnh CCCD (2 mặt thẻ)"
                setImagesFileList={setIDCardFileList}
              />

              <UploadImages
                typePhoto="anhBangCap"
                value={anhBangCap}
                multiple={true}
                maxCount={4}
                description="Ảnh bằng cấp (tối đa 4 ảnh)"
                setImagesFileList={setDegreeFileList}
              />
            </>
          )}

          <ReCapcha
            isCheckRecaptcha={isCheckRecaptcha}
            handleCheckRecaptcha={handleCheckRecaptcha}
          />

          <Form.Item>
            <Button
              className="button-form"
              htmlType="submit"
              disabled={loading}
            >
              Đăng ký
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SignUpTutors;
