import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FloatLabel from "~/components/FloatLabels/FloatLabel";
import ReCapcha from "~/components/ReCapcha";
import { useFocus, useThrottle, useTrapFocus } from "~/hooks";
import { getListStudents } from "~/pages/Tutors/redux/actions";
import { studentsSelector } from "~/pages/Tutors/redux/selectors";
import { STUDENTS } from "~/utils/constants/constants";
import { cityData, provinceData } from "~/utils/data/countryData";
import { lopHocList, monHocList } from "~/utils/data/infoData";
import { moneyFormat } from "~/utils/functions/common";
import {
  validateDiaChi,
  validateHoTen,
  validateHocPhi,
  validateLopHoc,
  validateMonHoc,
  validatePhuongXa,
  validateQuanHuyen,
  validateSdt,
  validateSoBuoi,
  validateSoPhut,
  validateThoiGian,
  validateThongTin,
  validateTinhTP,
  validateYeuCau,
} from "~/utils/validations/validationsInput";
import { signUpAction } from "../../redux/actions";

const initialValues = {
  sdt: "0123456789",
  hoTen: "Nguyễn Văn A",
  monHoc: "Toán, Lý, Hóa, Anh,...",
  lopHoc: "",
  tinhTP: "",
  quanHuyen: "",
  phuongXa: "Phường 1",
  diaChi: "15/34 hẻm 12 đường Nguyễn Huệ",
  hocPhi: "3000000",
  soBuoi: "3",
  soPhut: "90",
  thoiGian: "Tối 2, 4, 6 (19h - 20h30). Sắp xếp thứ 7, chủ nhật",
  thongTin: "Học sinh Nam, trường Nguyễn Huệ",
  yeuCau: "Giáo viên nữ, Sinh viên nam/nữ",
};

const { Option } = Select;

const LongForm = ({ error }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isCheckRecaptcha, setIsCheckRecaptcha] = useState(undefined);
  const [quanHuyenList, setQuanHuyenList] = useState([]);
  const [students, setStudents] = useState(initialValues);
  const {
    sdt,
    hoTen,
    monHoc,
    lopHoc,
    tinhTP,
    // quanHuyen,
    phuongXa,
    diaChi,
    hocPhi,
    soBuoi,
    soPhut,
    thoiGian,
    thongTin,
    yeuCau,
  } = students;

  const studentList = useSelector(studentsSelector);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setStudents((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s+/g, " ").trim(),
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const handleValueChange = (name) => (value) => {
    setStudents((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getListStudents());
  }, [dispatch]);

  const handleProvinceChange = (value) => {
    if (value) {
      const provinceLabel = provinceData.find(
        (val) => val.value === value
      )?.label;
      setQuanHuyenList(cityData[value]);
      setStudents((prevState) => ({
        ...prevState,
        tinhTP: provinceLabel,
        // quanHuyen: cityData[value],
      }));
      form.setFieldsValue({
        tinhTP: provinceLabel,
        quanHuyen: cityData[value][0].label,
      });
    } else {
      setStudents((prevState) => ({
        ...prevState,
        tinhTP: null,
        quanHuyen: null,
      }));
      form.setFieldsValue({ quanHuyen: undefined });
      setQuanHuyenList(undefined);
    }
  };

  const handleCityChange = (value) => {
    if (value) {
      const cityLabel = quanHuyenList.find((val) => val.value === value).label;
      setStudents((prevState) => ({
        ...prevState,
        quanHuyen: cityLabel,
      }));
      form.setFieldsValue({ quanHuyen: cityLabel });
    }
  };

  const onFinishLongForm = async (values) => {
    if (isCheckRecaptcha) {
      const maLop = studentList[0]?.maLop + 1 || 1;
      const student = {
        ...values,
        maLop,
        daGiao: false,
        maGiaSu: "",
        nguoiDung: "nguoiHoc",
        phanTramPhi: 0,
        xacNhan: false,
      };
      dispatch(signUpAction(student, STUDENTS));
      if (error) {
        return;
      } else {
        form.resetFields();
        dispatch(getListStudents());
      }
    } else {
      setIsCheckRecaptcha(false);
    }
  };

  const handleCheckRecaptcha = (value) => {
    setIsCheckRecaptcha(true);
  };

  useFocus("sdt");
  useTrapFocus("sign-up-students");

  return (
    <Form
      id="sign-up-students"
      form={form}
      name="register"
      onFinish={onFinishLongForm}
      scrollToFirstError
    >
      <p className="text-base font-medium my-4">Thông tin đăng ký</p>

      <FloatLabel label="Số điện thoại" name="sdt" value={sdt}>
        <Form.Item name="sdt" rules={validateSdt}>
          <Input
            id="sdt"
            placeholder={sdt}
            value={sdt}
            name="sdt"
            onChange={handleInputThrottled}
            maxLength={10}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Họ tên" name="hoTen" value={hoTen}>
        <Form.Item name="hoTen" rules={validateHoTen}>
          <Input
            placeholder={hoTen}
            value={hoTen}
            name="hoTen"
            onChange={handleInputThrottled}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Môn học" name="monHoc" value={monHoc}>
        <Form.Item name="monHoc" rules={validateMonHoc}>
          <Select
            mode="multiple"
            maxTagCount="responsive"
            onChange={handleValueChange("monHoc")}
            value={monHoc}
          >
            {monHocList.map((monHoc) => (
              <Option key={monHoc} value={monHoc}>
                {monHoc}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Lớp học" name="lopHoc" value={lopHoc}>
        <Form.Item name="lopHoc" rules={validateLopHoc}>
          <Select
            name="lopHoc"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            onChange={(value) =>
              setStudents((prevState) => ({
                ...prevState,
                lopHoc: value,
              }))
            }
          >
            {lopHocList.map((lop) => (
              <Select.Option key={lop} value={lop}>
                {lop}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </FloatLabel>

      <Row gutter={8}>
        <Col span={12}>
          <FloatLabel label="Tỉnh/TP" name="tinhTP" value={tinhTP}>
            <Form.Item name="tinhTP" rules={validateTinhTP}>
              <Select
                onChange={handleProvinceChange}
                name="tinhTP"
                options={provinceData}
                allowClear
              />
            </Form.Item>
          </FloatLabel>
        </Col>
        <Col span={12}>
          <FloatLabel label="Quận/Huyện" name="quanHuyen" value={quanHuyenList}>
            <Form.Item name="quanHuyen" rules={validateQuanHuyen}>
              <Select
                onChange={handleCityChange}
                name="quanHuyen"
                options={quanHuyenList}
                allowClear
              />
            </Form.Item>
          </FloatLabel>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <FloatLabel label="Phường/Xã" name="phuongXa" value={phuongXa}>
            <Form.Item name="phuongXa" rules={validatePhuongXa}>
              <Input
                placeholder={phuongXa}
                value={phuongXa}
                name="phuongXa"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>
        </Col>
        <Col span={12}>
          <FloatLabel label="Địa chỉ" name="diaChi" value={diaChi}>
            <Form.Item name="diaChi" rules={validateDiaChi}>
              <Input
                placeholder={diaChi}
                value={diaChi}
                name="diaChi"
                onChange={handleInputThrottled}
                allowClear
              />
            </Form.Item>
          </FloatLabel>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <FloatLabel label="Số buổi/tuần" name="soBuoi" value={soBuoi}>
            <Form.Item name="soBuoi" rules={validateSoBuoi}>
              <InputNumber
                type="number"
                placeholder={soBuoi}
                value={soBuoi}
                onChange={handleValueChange("soBuoi")}
                min={1}
                max={7}
              />
            </Form.Item>
          </FloatLabel>
        </Col>
        <Col span={12}>
          <FloatLabel label="Số phút/buổi" name="soPhut" value={soPhut}>
            <Form.Item name="soPhut" rules={validateSoPhut}>
              <InputNumber
                type="number"
                placeholder={soPhut}
                value={soPhut}
                onChange={handleValueChange("soPhut")}
                min={60}
                max={180}
                step={10}
              />
            </Form.Item>
          </FloatLabel>
        </Col>
      </Row>

      <FloatLabel label="Học phí/tháng" name="hocPhi" value={hocPhi}>
        <Form.Item name="hocPhi" rules={validateHocPhi}>
          <InputNumber
            placeholder={moneyFormat(hocPhi)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={100000}
            max={99999999}
            step={100000}
            onChange={handleValueChange("hocPhi")}
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Thời gian" name="thoiGian" value={thoiGian}>
        <Form.Item name="thoiGian" rules={validateThoiGian}>
          <Input
            placeholder={thoiGian}
            value={thoiGian}
            name="thoiGian"
            onChange={handleInputThrottled}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Thông tin" name="thongTin" value={thongTin}>
        <Form.Item name="thongTin" rules={validateThongTin}>
          <Input
            placeholder={thongTin}
            value={thongTin}
            name="thongTin"
            onChange={handleInputThrottled}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Yêu cầu" name="yeuCau" value={yeuCau}>
        <Form.Item name="yeuCau" rules={validateYeuCau}>
          <Input
            placeholder={yeuCau}
            value={yeuCau}
            name="yeuCau"
            onChange={handleInputThrottled}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <ReCapcha
        isCheckRecaptcha={isCheckRecaptcha}
        handleCheckRecaptcha={handleCheckRecaptcha}
      />

      <Form.Item>
        <Button className="button-form mt-5" htmlType="submit">
          Đăng ký
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LongForm;
