import { memo } from "react";
import Slider from "react-slick";

import {
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
} from "~/assets/img/home-gallery";
import "./HomeGallery.scss";

function HomeGallery() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const galleries = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
  ];

  return (
    <div className="gallery">
      <div className="container">
        <div className="title mb-8">
          <h1 className="text-3xl">Bộ sưu tập</h1>
          <p>Những phản hồi mới nhất từ phụ huynh và học viên</p>
        </div>

        <Slider {...settings} className="px-8 sm:px-14">
          {galleries.map((item, index) => {
            return (
              <div key={index} className="h-40 lg:h-48 xl:h-60">
                <img
                  src={item}
                  alt=""
                  className="rounded w-60 lg:w-72 xl:w-96 h-full object-cover"
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default memo(HomeGallery);
