import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useLayoutEffect } from "react";

import {
  TEL_NUMBER_1,
  TEL_NUMBER_2,
  TEL_NUMBER_SPACE_1,
  TEL_NUMBER_SPACE_2,
  TITLE,
} from "~/utils/constants/constants";
import { tuitionTable } from "~/utils/data/tuitionTable";
import "./styles.scss";

const Tuition = () => {
  useLayoutEffect(() => {
    document.title = `Học phí tham khảo | ${TITLE}`;
  }, []);

  return (
    <div className="tuition container">
      <h1 className="title">Học phí tham khảo</h1>
      <div>
        <p>Mặc định thời gian dạy của gia sư là:</p>
        <ul className="list-disc pl-4">
          <li>Giáo viên dạy 90 phút/buổi.</li>
          <li>Sinh Viên dạy 120 phút/buổi.</li>
        </ul>
        <p>
          Mức học phí áp dụng với 1 học sinh, nếu học nhóm hay có yêu cầu khác
          vui lòng liên hệ trung tâm.
        </p>
      </div>

      <br />
      <h3 className="text-center">
        Trung Tâm Gia Sư Mẫn Tiệp đưa ra bảng học phí 1 tháng dạy của gia sư cho
        quý phụ huynh và các bạn gia sư tham khảo:
      </h3>

      <div className="table-container">
        {tuitionTable.map((item, index) => (
          <table key={index} className="responsive-table">
            <caption>
              Bảng học phí theo tháng{" "}
              <span className="text-gray-700">{item.soBuoi} BUỔI/TUẦN</span>
            </caption>
            <thead>
              <tr>
                <th scope="col">Gia sư</th>
                <th scope="col">Sinh viên</th>
                <th scope="col">Giáo viên</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <td colSpan="3">Đơn vị: VNĐ/tháng</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <th scope="row">Cấp 1</th>
                <td data-title="Student Grade 1">{item.cap1.sinhVien}</td>
                <td data-title="Teacher Grade 1">{item.cap1.giaoVien}</td>
              </tr>
              <tr>
                <th scope="row">Cấp 2</th>
                <td data-title="Student Grade 2">{item.cap2.sinhVien}</td>
                <td data-title="Teacher Grade 2">{item.cap2.giaoVien}</td>
              </tr>
              <tr>
                <th scope="row">Cấp 3</th>
                <td data-title="Student Grade 3">{item.cap3.sinhVien}</td>
                <td data-title="Teacher Grade 3">{item.cap3.giaoVien}</td>
              </tr>
              <tr>
                <th scope="row">LTĐH - Ngoại Ngữ...</th>
                <td data-title="Student Foreign Language">
                  {item.ltdh.sinhVien}
                </td>
                <td data-title="Teacher Foreign Language">
                  {item.ltdh.giaoVien}
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>

      <div className="paragraph-bottom">
        Học phí trên áp dụng cho sinh viên và giáo viên có bằng cử nhân của các
        trường đại học khác hoặc giáo viên có bằng đại học sư phạm.
        <br />
        Đối với thạc sỹ, giáo viên thâm niên, giáo viên dạy tại trường có kinh
        nghiệm dạy kèm mức học phí được tính theo tiết (1 tiết = 45 phút):
        <br />
        <br />
        <ul className="list-disc pl-6">
          <li>Cấp 1: 160.000 - 220.000 đồng/buổi</li>
          <li>Cấp 2: 180.000 - 250.000 đồng/buổi</li>
          <li>Cấp 3: 220.000 - 300.000 đồng/buổi</li>
        </ul>
        <br />
        <p className="attention text-red-500">
          <FontAwesomeIcon icon={faWarning} color="red" /> Lưu ý: Mức học phí
          trên là tham khảo, mức học phí sẽ tăng hoặc giảm theo yêu cầu : số môn
          học, số người học, địa điểm học và các yêu cầu thêm khác.
        </p>
        <br />
        <div className="text-center font-medium">
          Để biết chính xác mức học phí và được tư vấn miễn phí tìm gia sư giỏi,
          chất lượng quý phụ huynh và học viên vui lòng liên hệ:
          <p className="text-orange-500 font-semibold">
            TRUNG TÂM GIA SƯ MẪN TIỆP
          </p>
          <p className="text-orange-500 font-semibold">
            ĐT:{" "}
            <a className="text-orange-500" href={`tel:${TEL_NUMBER_1}`}>
              {TEL_NUMBER_SPACE_1}
            </a>
            {" - "}
            <a className="text-orange-500" href={`tel:${TEL_NUMBER_2}`}>
              {TEL_NUMBER_SPACE_2}
            </a>
          </p>
          Chúng tôi luôn đồng hành và hỗ trợ trong suốt quá trình học tập
          <br />
          Vì tất cả học sinh thân yêu hãy để cho chúng tôi thắp sáng ước mơ của
          các em bằng con đường "Giáo dục"
          <br />
          <br />
          <p className="text-blue-900">
            Chân thành cảm ơn quý phụ huynh, học viên và các bạn gia sư đã quan
            tâm và tín nhiệm chúng tôi trong suốt thời gian qua.
            <br />
            Kính chúc quý phụ huynh, học viên và các bạn gia sư sức khỏe, thành
            đạt.
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Tuition);
