import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";

import Spinner from "~/components/Loading/Spinner";
import { TITLE } from "~/utils/constants/constants";
import { SIGN_UP } from "~/utils/constants/pathUrl";
import ShortForm from "./components/ShortForm";
import LongForm from "./components/LongForm";

import "./styles.scss";

const SignUpStudents = (props) => {
  const user = useSelector((state) => state.registerTutorState);
  const { loading, error } = user;

  useLayoutEffect(() => {
    document.title = `Đăng ký tìm gia sư | ${TITLE}`;
  }, []);

  return (
    <div className="signup-students">
      <div className="container">
        <Link
          className="flex text-blue-900 w-24 hover:text-blue-900"
          to={`/${SIGN_UP}`}
        >
          <ArrowSmLeftIcon className="h-5 w-5 mr-1" />
          Quay lại
        </Link>
        <h1 className=" text-2xl my-3">Đăng ký tìm gia sư</h1>
        <p>
          Quý phụ huynh và học viên chọn đăng ký nhanh hoặc đăng ký đầy đủ thông
          tin trong form dưới đây.
        </p>

        <Tabs
          onChange={(key) => {}}
          // type="card"
          items={[
            {
              key: "1",
              label: "Điền thông tin nhanh",
              children: <ShortForm error={error} />,
            },
            {
              key: "2",
              label: "Điền thông tin đầy đủ",
              children: <LongForm error={error} />,
            },
          ]}
        />
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SignUpStudents;
