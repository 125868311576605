import HomeCarousel from "./HomeCarousel/HomeCarousel";
import HomeChooseUs from "./HomeChooseUs/HomeChooseUs";
import HomeGallery from "./HomeGallery/HomeGallery";
import HomeLoveUs from "./HomeLoveUs/HomeLoveUs";
import HomeProcess from "./HomeProcess/HomeProcess";
import HomeSubject from "./HomeSubject/HomeSubject";
import HomeTutor from "./HomeTutor";

function Home(props) {
  return (
    <>
      <HomeCarousel />
      <HomeSubject />
      <HomeProcess />
      <HomeChooseUs />
      <HomeLoveUs />
      <HomeTutor />
      <HomeGallery />
    </>
  );
}

export default Home;
