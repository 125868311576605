export const SIGN_IN = "dang-nhap";
export const SIGN_UP = "dang-ky";
export const FORGOT_PASSWORD = "quen-mat-khau";

export const STUDENT = "nguoi-hoc";
export const FIND_TUTOR = "danh-sach-gia-su";
export const TUITION = "hoc-phi-tham-khao";
export const TUTORING_SERVICE = "dich-vu-gia-su";

export const TUTOR = "nguoi-day";
export const NEW_CLASS = "lop-moi";
export const TUTOR_INFO = "thong-tin-gia-su";
export const EDIT_FROFILE = "chinh-sua-thong-tin";
export const INSTRUCTIONS = "huong-dan-nhan-lop";
export const CONTRACT = "hop-dong-gia-su";

export const CONTACT = "lien-he";
