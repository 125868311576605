import { useState } from "react";
import { Button, Form, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Compressor from "compressorjs";
import ImgCrop from "antd-img-crop";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImgCropUpload = (props) => (
  <ImgCrop>
    <Upload {...props}>{props.children}</Upload>
  </ImgCrop>
);

const UploadImages = (props) => {
  const { typePhoto, multiple, maxCount, description, setImagesFileList } =
    props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || "Ảnh xem trước");
  };

  const handleRemove = () => setFileList([]);

  const handleChange = ({ fileList: newFileList }) => {
    let stag = [];
    newFileList.forEach(async (file) => {
      if (!file) {
        return;
      }

      new Compressor(file.originFileObj, {
        maxWidth: 800,
        quality: 0.8,
        checkOrientation: false,

        success(result) {
          stag.push(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    });

    setImagesFileList(stag);
    setFileList(newFileList);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList((prev) => [...prev, { url: reader.result }]);

      fetch(reader.result)
        .then((res) => res.blob())
        .then((imgBlob) => {
          new Compressor(imgBlob, {
            maxWidth: 800,
            convertTypes: ["image/png"],

            success(result) {
              setImagesFileList([result]);
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
    };

    // then upload `file` from the argument manually
    return false;
  };

  return (
    <>
      <Form.Item name={typePhoto} getValueFromEvent={normFile}>
        {typePhoto === "anhDaiDien" ? (
          <ImgCropUpload
            name={typePhoto}
            listType="picture"
            beforeUpload={beforeUpload}
            fileList={fileList}
            onPreview={handlePreview}
            // onChange={handleChange}
            onRemove={handleRemove}
          >
            {/* {fileList.length < 1 && (
              <div>
                <UploadOutlined />
                <p>{description}</p>
              </div>
            )} */}
            <Button icon={<UploadOutlined />}>{description}</Button>
          </ImgCropUpload>
        ) : (
          <Upload
            name={typePhoto}
            listType="picture"
            beforeUpload={() => false}
            multiple={multiple}
            maxCount={maxCount}
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            <Button icon={<UploadOutlined />}>{description}</Button>
          </Upload>
        )}
      </Form.Item>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt={typePhoto}
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default UploadImages;
