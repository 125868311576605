import { useState, memo } from "react";
import Slider from "react-slick";
import "animate.css";
import Illustration1 from "~/assets/svg/slider/illustration1.svg";
import Illustration2 from "~/assets/svg/slider/illustration2.svg";
import Illustration3 from "~/assets/svg/slider/illustration3.svg";

import "./HomeCarousel.scss";
import { Link } from "react-router-dom";
import { SIGN_UP, STUDENT } from "~/utils/constants/pathUrl";

function HomeCarousel(props) {
  const [state, setState] = useState({
    activeSlide: 0,
    activeSlide2: 0,
  });

  const url = `/${SIGN_UP}/${STUDENT}`;

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    beforeChange: (current, next) => setState({ activeSlide: next }),
    afterChange: (current) => setState({ activeSlide2: current }),
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        <div>
          {state.activeSlide === 0 && (
            <div className="slider">
              <div className="left">
                <div className="content">
                  <div className="slide-animate ">
                    <h1 className="title animate__animated animate__slideInDown">
                      Gia sư <span className="text-blue-900">Mẫn Tiệp</span>
                    </h1>

                    <p className="subtitle animate__animated animate__slideInUp">
                      nơi đảm bảo gia sư uy tín, chất lượng
                      <br />
                      cải thiện học tập của người học rõ rệt
                    </p>

                    <Link
                      className="text-white animate__animated animate__zoomIn text-base sm:mr-2 md:mr-4 bg-blue-900 px-2 md:px-3 py-2 rounded"
                      to={url}
                    >
                      TÌM GIA SƯ NGAY
                    </Link>
                  </div>
                </div>
              </div>
              <div className="illustration">
                <img src={Illustration1} alt="illustration1" className="" />
              </div>
            </div>
          )}
        </div>

        <div>
          {state.activeSlide === 1 && (
            <div className="slider">
              <div className="left">
                <div className="content">
                  <div className="slide-animate ">
                    <h1 className="title animate__animated animate__rotateInDownLeft">
                      Gia sư <span className="text-blue-900">Mẫn Tiệp</span>
                    </h1>
                    <p className="subtitle animate__animated animate__rotateInDownRight">
                      luôn truyền đạt với sự nhiệt thành <br /> đặt mục tiêu
                      người học lên hàng đầu
                    </p>

                    <Link
                      className="text-white animate__animated animate__zoomIn text-base sm:mr-2 md:mr-4 bg-blue-900 px-2 md:px-3 py-2 rounded"
                      to={url}
                    >
                      TÌM GIA SƯ NGAY
                    </Link>
                  </div>
                </div>
              </div>
              <div className="illustration">
                <img src={Illustration2} alt="illustration2" className="" />
              </div>
            </div>
          )}
        </div>
        <div>
          {state.activeSlide === 2 && (
            <div className="slider">
              <div className="left">
                <div className="content">
                  <div className="slide-animate ">
                    <h1 className="title animate__animated animate__rotateIn">
                      Gia sư <span className="text-blue-900">Mẫn Tiệp</span>
                    </h1>
                    <p className="subtitle animate__animated animate__rollIn">
                      có nhiều gia sư giỏi tại các trường hàng đầu cả nước
                      <br /> luôn luôn tâm huyết với nghề
                    </p>

                    <Link
                      className="text-white animate__animated animate__zoomIn text-base sm:mr-2 md:mr-4 bg-blue-900 px-2 md:px-3 py-2 rounded"
                      to={url}
                    >
                      TÌM GIA SƯ NGAY
                    </Link>
                  </div>
                </div>
              </div>
              <div className="illustration">
                <img src={Illustration3} alt="illustration3" className="" />
              </div>
            </div>
          )}
        </div>
      </Slider>
    </div>
  );
}

export default memo(HomeCarousel);
