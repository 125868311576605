import { Link } from "react-router-dom";

import RaisingHandPana from "~/assets/svg/sign up/Raising-hand-pana.svg";
import TeacherPana from "~/assets/svg/sign up/Teacher-pana.svg";
import { STUDENT, TUTOR } from "~/utils/constants/pathUrl";
import "./styles.scss";

const SignUpUser = () => {
  return (
    <div className="login-layout">
      <div className="text-3xl text-black font-semibold">ĐĂNG KÝ</div>
      <p className="subtitle">Bạn là...</p>
      <div className="card flex justify-center">
        <div className="m-4 p-1">
          <div className="item">
            <img className="w-full" src={RaisingHandPana} alt="role" />
            <div className="p-4">
              <Link
                className="text-lg text-blue-900 border-blue-900 border px-3 py-2 rounded-md"
                to={STUDENT}
              >
                Người học
              </Link>
            </div>
          </div>
        </div>

        <div className="m-4 p-1">
          <div className="item">
            <img className="w-full" src={TeacherPana} alt="role" />
            <div className="p-4">
              <Link
                className="text-lg text-blue-900 border-blue-900 border px-3 py-2 rounded-md"
                to={TUTOR}
              >
                Gia sư
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpUser;
