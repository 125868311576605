import { createSelector } from "reselect";
import {
  filterMonHocSelector,
  filterLopHocSelector,
  filterGioiTinhSelector,
  filterTrinhDoSelector,
  filterTinhThanhSelector,
  filterQuanHuyenSelector,
} from "~/components/FilterComponent/redux/selectors";

export const tutorsSelector = (state) => state.tutorsState.data;

export const tutorsRemainingSelector = createSelector(
  tutorsSelector,
  filterMonHocSelector,
  filterLopHocSelector,
  filterGioiTinhSelector,
  filterTrinhDoSelector,
  filterTinhThanhSelector,
  filterQuanHuyenSelector,
  (
    tutorList,
    monHocText,
    lopHocText,
    gioiTinh,
    trinhDo,
    tinhThanhText,
    quanHuyenText
  ) => {
    return tutorList.filter((tutor) => {
      return (
        tutor.xacNhan &&
        (monHocText ? tutor.monDay.includes(monHocText) : true) &&
        (lopHocText ? tutor.lopDay.includes(lopHocText) : true) &&
        (gioiTinh.length === 1
          ? tutor.gioiTinh.toLowerCase().includes(gioiTinh)
          : true) &&
        (trinhDo.length === 1 ? tutor.trinhDo.includes(trinhDo) : true) &&
        (tinhThanhText ? tutor.tinhThanhDay.includes(tinhThanhText) : true) &&
        (quanHuyenText ? tutor.quanHuyenDay.includes(quanHuyenText) : true)
      );
    });
  }
);
