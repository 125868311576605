export const provinceData = [
  {
    value: "hoChiMinh",
    label: "Hồ Chí Minh",
  },
  {
    value: "binhDuong",
    label: "Bình Dương",
  },
  {
    value: "dongNai",
    label: "Đồng Nai",
  },
  {
    value: "binhDinh",
    label: "Bình Định",
  },
];

export const cityData = {
  hoChiMinh: [
    { value: "quan1", label: "Quận 1" },
    { value: "quan2", label: "Quận 2" },
    { value: "quan3", label: "Quận 3" },
    { value: "quan4", label: "Quận 4" },
    { value: "quan5", label: "Quận 5" },
    { value: "quan6", label: "Quận 6" },
    { value: "quan7", label: "Quận 7" },
    { value: "quan8", label: "Quận 8" },
    { value: "quan9", label: "Quận 9" },
    { value: "quan10", label: "Quận 10" },
    { value: "quan11", label: "Quận 11" },
    { value: "quan12", label: "Quận 12" },
    { value: "binhTan", label: "Bình Tân" },
    { value: "binhThanh", label: "Bình Thạnh" },
    { value: "goVap", label: "Gò Vấp" },
    { value: "phuNhuan", label: "Phú Nhuận" },
    { value: "tanBinh", label: "Tân Bình" },
    { value: "tanPhu", label: "Tân Phú" },
    { value: "thuDuc", label: "Thủ Đức" },
    { value: "binhChanh", label: "Bình Chánh" },
    { value: "canGio", label: "Cần Giờ" },
    { value: "cuChi", label: "Củ Chi" },
    { value: "hocMon", label: "Hóc Môn" },
    { value: "nhaBe", label: "Nhà Bè" },
  ],
  binhDuong: [
    { value: "bacTanUyen", label: "Bắc Tân Uyên" },
    { value: "bauBang", label: "Bàu Bàng" },
    { value: "benCat", label: "Bến Cát" },
    { value: "dauTieng", label: "Dầu Tiếng" },
    { value: "diAn", label: "Dĩ An" },
    { value: "phuGiao", label: "Phú Giáo" },
    { value: "tanUyen", label: "Tân Uyên" },
    { value: "thuDauMot", label: "Thủ Dầu Một" },
    { value: "thuanAn", label: "Thuận An" },
  ],
  dongNai: [
    { value: "camMy", label: "Cẩm Mỹ" },
    { value: "dinhQuan", label: "Định Quán" },
    { value: "longThanh", label: "Long Thành" },
    { value: "nhonTrach", label: "Nhơn Trạch" },
    { value: "tanPhu", label: "Tân Phú" },
    { value: "thongNhat", label: "Thống Nhất" },
    { value: "trangBom", label: "Trảng Bom" },
    { value: "vinhCuu", label: "Vĩnh Cửu" },
    { value: "xuanLoc", label: "Xuân Lộc" },
    { value: "bienHoa", label: "Biên Hoà" },
    { value: "longKhanh", label: "Long khánh" },
  ],
  binhDinh: [
    { value: "quyNhon", label: "Quy Nhơn" },
    { value: "anNhon", label: "An Nhơn" },
    { value: "hoaiNhon", label: "Hoài Nhơn" },
    { value: "anLao", label: "An Lão" },
    { value: "hoaiAn", label: "Hoài Ân" },
    { value: "phuCat", label: "Phù Cát" },
    { value: "phuMy", label: "Phù Mỹ" },
    { value: "taySon", label: "Tây Sơn" },
    { value: "tuyPhuoc", label: "Tuy Phước" },
    { value: "vanCanh", label: "Vân Canh" },
    { value: "vinhThanh", label: "Vĩnh Thạnh" },
  ],
};
