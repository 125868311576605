import { memo } from "react";
import Slider from "react-slick";
import toan from "~/assets/img/subject-icon/toan.png";
import ly from "~/assets/img/subject-icon/ly.png";
import hoa from "~/assets/img/subject-icon/hoa.png";
import sinh from "~/assets/img/subject-icon/sinh.png";
import tin from "~/assets/img/subject-icon/tin.png";
import van from "~/assets/img/subject-icon/van.png";
import su from "~/assets/img/subject-icon/su.png";
import dia from "~/assets/img/subject-icon/dia.png";
import ngoaingu from "~/assets/img/subject-icon/ngoaingu.png";
import ve from "~/assets/img/subject-icon/ve.png";
import khac from "~/assets/img/subject-icon/khac.png";
import "./HomeSubject.scss";

function HomeSubject() {
  const subjects = [
    { image: toan, name: "Toán học" },
    { image: ly, name: "Vật lý" },
    { image: hoa, name: "Hóa học" },
    { image: sinh, name: "Sinh học" },
    { image: tin, name: "Tin học" },
    { image: van, name: "Ngữ văn" },
    { image: su, name: "Lịch sử" },
    { image: dia, name: "Địa lý" },
    { image: ngoaingu, name: "Ngoại ngữ" },
    { image: ve, name: "Mĩ thuật" },
    { image: khac, name: "Môn học khác" },
  ];
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="subjects">
      <div className="container">
        <div className="title">
          <h1 className="text-3xl">Các môn học</h1>
          <p>Có đầy đủ các bộ môn từ mẫu giáo đến đại học</p>
        </div>

        <Slider {...settings} className="overflow-hidden px-10 md:px-16">
          {subjects.map((item, index) => (
            <div className="card py-5 rounded-md" key={index}>
              <img src={item.image} alt={item.name} className="w-32" />
              <h2 className="text-xl my-3">{item.name}</h2>
              <button className="text-base text-white bg-blue-900 lg:bg-white lg:text-blue-900 px-2 md:px-3 py-1 rounded">
                ĐẶT LỚP
              </button>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default memo(HomeSubject);
