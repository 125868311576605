import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  faChalkboardUser,
  faFilter,
  faMars,
  faPersonChalkboard,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, List, Modal } from "antd";

import FilterComponent from "~/components/FilterComponent";
import {
  getListFilter,
  gioiTinhFilterChange,
  lopHocFilterChange,
  maLopFilterChange,
  monHocFilterChange,
  quanHuyenFilterChange,
  tinhThanhFilterChange,
  trinhDoFilterChange,
} from "~/components/FilterComponent/redux/actions";
import { filterSelector } from "~/components/FilterComponent/redux/selectors";
import { TITLE } from "~/utils/constants/constants";
import { getListStudents } from "~/pages/Tutors/redux/actions";
import { studentsRemainingSelector } from "~/pages/Tutors/redux/selectors";
import Student from "../Student";
import { SIGN_UP } from "~/utils/constants/pathUrl";

import "./styles.scss";
import { scrollToTop } from "~/utils/functions/common";

const NewClass = (props) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueGioiTinh, setValueGioiTinh] = useState("");
  const [valueTrinhDo, setValueTrinhDo] = useState("");
  const [inputKey, setInputKey] = useState("");
  const [unassignedClass, setUnassignedClass] = useState();
  const studentList = useSelector(studentsRemainingSelector);
  const filterList = useSelector(filterSelector);

  useEffect(() => {
    dispatch(getListStudents());
    dispatch(getListFilter());
  }, [dispatch]);

  useLayoutEffect(() => {
    document.title = `Lớp mới | ${TITLE}`;
    const unassigned = studentList.filter((item) => item.daGiao === false);
    setUnassignedClass(unassigned);
  }, [studentList]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    scrollToTop();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangePagination = (page) => {
    scrollToTop();
  };

  const handleGioiTinhChange = (checkedValues) => {
    const { value } = checkedValues.target;
    if (valueGioiTinh === value) {
      setValueGioiTinh("");
      dispatch(gioiTinhFilterChange([]));
    } else {
      setValueGioiTinh(value);
      dispatch(gioiTinhFilterChange([value]));
    }
  };

  const handleTrinhDoRadioChange = (checkedValues) => {
    const { value } = checkedValues.target;
    if (valueTrinhDo === value) {
      setValueTrinhDo("");
      dispatch(trinhDoFilterChange([]));
    } else {
      setValueTrinhDo(value);
      dispatch(trinhDoFilterChange([value]));
    }
  };

  const handleClearTag = (key) => {
    setInputKey(`${key}`);

    switch (key) {
      case "maLop":
        return dispatch(maLopFilterChange(""));
      case "lopHoc":
        return dispatch(lopHocFilterChange(""));
      case "monHoc":
        return dispatch(monHocFilterChange(""));
      case "tinhThanh":
        dispatch(tinhThanhFilterChange(""));
        dispatch(quanHuyenFilterChange(""));
        return;
      case "quanHuyen":
        return dispatch(quanHuyenFilterChange(""));
      default:
        return null;
    }
  };

  return (
    <section className="search-section px-3">
      <div className="container">
        <div className="search-header flex justify-between items-center mb-4">
          <h1
            className="search-caption font-light text-xl mb-1 mr-6"
            id="search-results-heading"
          >
            Tìm lớp mới
          </h1>
          <div className="signup-button hidden lg:block">
            <Link
              className="text-base bg-blue-900 px-2 md:px-4 py-3 rounded text-white"
              to={`/${SIGN_UP}`}
            >
              + Đăng ký làm gia sư
            </Link>
          </div>
        </div>

        <hr className="separator mb-6 hidden lg:block" />

        <div className="search-component">
          <div className="flex flex-col lg:flex-row">
            <div className="hidden lg:block">
              <FilterComponent role="student" />
            </div>

            {/* Mobile view */}
            <div className="block lg:hidden">
              <div className="filter-radio-mobile">
                <div className="filter-radio-mobile-item mb-2">
                  {["nữ", "nam"].map((item, index) => {
                    return (
                      <Checkbox
                        key={index}
                        className="option"
                        checked={item === valueGioiTinh}
                        value={item}
                        onChange={handleGioiTinhChange}
                      >
                        <FontAwesomeIcon
                          icon={item === "nam" ? faMars : faVenus}
                          color={item === "nam" ? "#02a2ff" : "#ec48a7"}
                        />
                        <span className="ml-2">Gia sư {item}</span>
                      </Checkbox>
                    );
                  })}
                </div>

                <div className="filter-radio-mobile-item">
                  {["Giáo viên", "Sinh viên"].map((item, index) => {
                    return (
                      <Checkbox
                        key={index}
                        className="option"
                        checked={item === valueTrinhDo}
                        value={item}
                        onChange={handleTrinhDoRadioChange}
                      >
                        <FontAwesomeIcon
                          icon={
                            item === "Sinh viên"
                              ? faChalkboardUser
                              : faPersonChalkboard
                          }
                          color={item === "Sinh viên" ? "#8dee91" : "#ff7b76"}
                        />
                        <span className="ml-2">
                          {item === "Sinh viên" ? "Sinh viên" : "Giáo viên"}
                        </span>
                      </Checkbox>
                    );
                  })}
                </div>
              </div>

              <div className="active-filters-container my-1 overflow-hidden text-left">
                {Object.keys(filterList).map((key, index) => {
                  if (
                    filterList[key] === "" ||
                    typeof filterList[key] !== "string"
                  )
                    return null;

                  return (
                    <Button
                      key={index}
                      className="px-3 bg-white rounded-full text-gray-600 m-1 text-sm"
                      onClick={() => handleClearTag(key)}
                    >
                      {filterList[key]} x
                    </Button>
                  );
                })}
              </div>
            </div>

            <div className="cards lg:ml-6 w-full">
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  onChange: handleChangePagination,
                  pageSize: window.innerWidth > 768 ? 10 : 5,
                  hideOnSinglePage: true,
                }}
                dataSource={studentList}
                header={
                  <div className="header-total">
                    <p>{unassignedClass?.length || 0} lớp mới</p>
                    <div className="modal-filter-button lg:hidden">
                      <FontAwesomeIcon
                        className="p-4"
                        icon={faFilter}
                        onClick={showModal}
                      />
                      <Modal
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={[
                          <button
                            key="filter"
                            className="bg-blue-900 text-white text-base py-2 w-full"
                            onClick={handleOk}
                          >
                            Tìm kiếm
                          </button>,
                        ]}
                      >
                        <FilterComponent role="student" inputKey={inputKey} />
                      </Modal>
                    </div>
                  </div>
                }
                renderItem={(item) => {
                  return <Student student={item} />;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewClass;
