import ReCAPTCHA from "react-google-recaptcha";

const ReCapcha = ({ isCheckRecaptcha, handleCheckRecaptcha }) => {
  const handleChangeRecaptcha = (value) => {
    handleCheckRecaptcha(true);
  };
  const handleExpiredRecaptcha = (value) => {
    handleCheckRecaptcha(false);
  };
  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_reCAPTCHA_SITE_KEY}
        onChange={handleChangeRecaptcha}
        onExpired={handleExpiredRecaptcha}
      />
      {isCheckRecaptcha === false && (
        <span className="text-red-500">
          Vui lòng xác nhận bạn không phải là robot!
        </span>
      )}
    </>
  );
};

export default ReCapcha;
