import { memo, useLayoutEffect } from "react";
import {
  TEL_NUMBER_1,
  TEL_NUMBER_2,
  TEL_NUMBER_SPACE_1,
  TEL_NUMBER_SPACE_2,
  TITLE,
} from "~/utils/constants/constants";
import "./styles.scss";

const TutoringService = () => {
  useLayoutEffect(() => {
    document.title = `Dịch vụ gia sư | ${TITLE}`;
  }, []);

  return (
    <div className="tutoring-service container">
      <h1 className="title">Dịch vụ gia sư</h1>

      <div className="panel-body">
        <div className="part">
          <h3>DỊCH VỤ GIẢNG DẠY</h3>
          <a href="https://www.giasumantiep.com/">Gia sư Mẫn Tiệp</a> chuyên
          cung ứng dịch vụ gia sư chất lượng dạy kèm tại nhà học viên tất cả các
          tỉnh thành.
          <ul className="list-disc pl-4">
            <li>
              Nhận dạy kèm từ lớp 1 đến lớp 12 tất cả Các Môn, đủ mọi trình độ
              theo yêu cầu của học viên.
            </li>
            <li>
              Nhận dạy luyện thi chuyển cấp Lớp 5 lên 6, Lớp 9 lên 10 và LTĐH,
              Thi vào các trường chuyên, Lớp chọn.
            </li>
            <li>
              Nhận dạy kèm Ngoại ngữ: Anh, Pháp, Nhật, Hàn, Hoa … từ căn bản đến
              nâng cao.
            </li>
            <li>Nhận dạy kèm tin học từ cơ bản đến nâng cao.</li>
            <li>Nhận dạy kèm các môn năng khiếu: Đàn, Nhạc, Vẽ,...</li>
            <li>Nhận dạy luyện thi các văn bằng, các chứng chỉ quốc tế.</li>
          </ul>
        </div>

        <br />

        <div className="part">
          <h3>PHƯƠNG THỨC HỌC TẬP</h3>

          <ul className="list-disc pl-4">
            <li>
              Gia sư đến tận nhà học sinh kiểm tra đánh giá trình độ và đưa ra
              phương pháp dạy và học cho học sinh, lên giáo án giảng dạy cho phù
              hợp với từng học viên.
            </li>
            <li>
              Gia sư tư vấn cho phụ huynh và học sinh chọn môn thi, khối thi,
              trường thi phù hợp năng lực và sở trường của từng em.
            </li>
            <li>
              Gia sư luôn kết hợp với học sinh để biên soạn giáo trình dạy phù
              hợp theo năng lực của từng học sinh.
            </li>
            <li>
              Gia sư thường xuyên hệ thống bài giảng bài kiểm tra hằng ngày từ
              trường lớp và chương trình mới của bộ giáo dục.
            </li>
            <li>
              Gia sư kết hợp phụ huynh luôn theo sát trong quá trình giảng dạy
              của gia sư và đưa ra đánh giá kết quả học tập qua từng tháng của
              học sinh.
            </li>
          </ul>
        </div>

        <br />

        <div className="part">
          <h3>MỤC ĐÍCH DẠY KÈM</h3>

          <ul className="list-disc pl-4">
            <li>
              Bám sát chương trình cải cách mới của Bộ Giáo dục và Đào tạo.
            </li>
            <li>
              Dạy kèm sát báo bài, khối lượng bài giải tại nhà của từng học
              sinh.
            </li>
            <li>Lấy lại kiến thức cho học sinh yếu.</li>
            <li>Nâng cao kiến thức cho học sinh khá giỏi.</li>
            <li>Thiết lập giáo án phù hợp năng lực từng học sinh.</li>
            <li>Luôn mở rộng và nâng cao kiến thức cho học sinh khá giỏi.</li>
            <li>
              Bồi dưỡng kiến thức cho các thí sinh chuẩn bị tham dự các cuộc
              thi: Học sinh giỏi các cấp, các chương trình học bổng do các tổ
              chức giáo dục tổ chức.
            </li>
            <li>
              Ngoài việc truyền đạt kiến thức, chúng tôi mong muốn sẽ cùng với
              Quý Phụ Huynh góp phần hình thành nhân cách, giáo dục đạo đức cho
              các em để Quý Phụ Huynh yên tâm trong công việc gia đình và xã
              hội.
            </li>
          </ul>
        </div>

        <br />

        <div className="part">
          <h3>CAM KẾT CHẤT LƯỢNG</h3>

          <ul className="list-disc pl-4">
            <li>
              Hiệu quả - uy tín là tiêu chí hoạt động hàng đầu của Gia Sư Mẫn
              Tiệp.
            </li>
            <li>
              Phối hợp với phụ huynh kiểm tra chất lượng của gia sư trong suốt
              quá trình học.
            </li>
            <li>
              Hủy ngay những hợp đồng gia sư dạy kèm giảng dạy thiếu nhân cách
              và tri thức. Thực hiện đổi ngay gia sư uy tín để đảm bảo việc học
              của học sinh.
            </li>
            <li>
              Tư vấn Gia sư phù hợp với trình độ của học sinh (Phụ huynh và học
              sinh có quyền đề cử giáo viên thích hợp).
            </li>
            <li>Cam kết học sinh tiến bộ sau một tháng.</li>
            <li>
              Phụ huynh chỉ phải đóng phí khi hài lòng về gia sư giảng dạy.
            </li>
          </ul>
        </div>
      </div>

      <br />

      <div className="text-center font-medium">
        Để biết chính xác mức học phí và được tư vấn miễn phí tìm gia sư giỏi,
        chất lượng quý phụ huynh và học viên vui lòng liên hệ:
        <p className="text-orange-500 font-semibold">
          TRUNG TÂM GIA SƯ MẪN TIỆP
        </p>
        <p className="text-orange-500 font-semibold">
          ĐT:{" "}
          <a className="text-orange-500" href={`tel:${TEL_NUMBER_1}`}>
            {TEL_NUMBER_SPACE_1}
          </a>
          {" - "}
          <a className="text-orange-500" href={`tel:${TEL_NUMBER_2}`}>
            {TEL_NUMBER_SPACE_2}
          </a>
        </p>
        Chúng tôi luôn đồng hành và hỗ trợ trong suốt quá trình học tập
        <br />
        Vì tất cả học sinh thân yêu hãy để cho chúng tôi thắp sáng ước mơ của
        các em bằng con đường "Giáo dục"
        <br />
        <br />
        <p className="text-blue-900">
          Chân thành cảm ơn quý phụ huynh, học viên và các bạn gia sư đã quan
          tâm và tín nhiệm chúng tôi trong suốt thời gian qua.
          <br />
          Kính chúc quý phụ huynh, học viên và các bạn gia sư sức khỏe, thành
          đạt.
        </p>
      </div>
    </div>
  );
};

export default memo(TutoringService);
