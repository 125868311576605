import { faFilter, faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, List, Modal, Select } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FilterComponent from "~/components/FilterComponent";
import {
  getListFilter,
  gioiTinhFilterChange,
  lopHocFilterChange,
  maLopFilterChange,
  monHocFilterChange,
  quanHuyenFilterChange,
  tinhThanhFilterChange,
  trinhDoFilterChange,
} from "~/components/FilterComponent/redux/actions";
import { filterSelector } from "~/components/FilterComponent/redux/selectors";
import { TITLE } from "~/utils/constants/constants";
import { SIGN_UP, STUDENT } from "~/utils/constants/pathUrl";
import { trinhDoList } from "~/utils/data/infoData";
import { getMultipleRandom, scrollToTop } from "~/utils/functions/common";
import { getListTutors } from "../../redux/actions";
import { tutorsRemainingSelector } from "../../redux/selectors";
import TutorList from "./components/TutorList";

import "./styles.scss";

const { Option } = Select;

const FindTutors = (props) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueGioiTinh, setValueGioiTinh] = useState("");
  const [inputKey, setInputKey] = useState("");
  const tutors = useSelector(tutorsRemainingSelector);
  const [tutorListShuffle, setTutorListShuffle] = useState();
  const filterList = useSelector(filterSelector);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    scrollToTop();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getListTutors());
    dispatch(getListFilter());
  }, [dispatch]);

  useLayoutEffect(() => {
    document.title = `Danh sách gia sư | ${TITLE}`;

    const shuffle = getMultipleRandom(tutors, tutors.length);
    setTutorListShuffle(shuffle);
  }, [tutors]);

  const handleChangePagination = (page) => {
    scrollToTop();
  };

  const handleGioiTinhChange = (checkedValues) => {
    const { value } = checkedValues.target;
    if (valueGioiTinh === value) {
      setValueGioiTinh("");
      dispatch(gioiTinhFilterChange([]));
    } else {
      setValueGioiTinh(value);
      dispatch(gioiTinhFilterChange([value]));
    }
  };

  const handleTrinhDoSelectChange = (value) => {
    dispatch(trinhDoFilterChange([value]));
  };

  const handleClearTag = (key) => {
    setInputKey(`${key}`);

    switch (key) {
      case "maLop":
        return dispatch(maLopFilterChange(undefined));
      case "lopHoc":
        return dispatch(lopHocFilterChange(undefined));
      case "monHoc":
        return dispatch(monHocFilterChange(undefined));
      case "tinhThanh":
        dispatch(tinhThanhFilterChange(undefined));
        dispatch(quanHuyenFilterChange(undefined));
        return;
      case "quanHuyen":
        return dispatch(quanHuyenFilterChange(undefined));
      default:
        return null;
    }
  };

  return (
    <section className="search-section px-3">
      <div className="container">
        <div className="flex justify-between items-center mb-4">
          <h1
            className="search-caption font-light text-xl mb-1 mr-6"
            id="search-results-heading"
          >
            Tìm gia sư
          </h1>
          <div className="hidden lg:flex lg:flex-col ">
            <span className="font-medium">Không tìm thấy gia sư bạn cần?</span>
            <Link
              className="text-base bg-blue-900 px-2 md:px-3 py-2 rounded text-white hover:text-white hover:bg-orange-600"
              to={`/${SIGN_UP}/${STUDENT}`}
            >
              + Đăng ký tìm gia sư mới
            </Link>
          </div>
        </div>

        <hr className="separator mb-6 hidden lg:block" />

        <div className="search-component">
          <div className="flex flex-col lg:flex-row">
            <div className="hidden lg:block">
              <FilterComponent role="tutor" />
            </div>

            {/* Mobile view */}
            <div className="block lg:hidden">
              <div className="mb-2">
                <Select
                  className="w-full"
                  showSearch
                  placeholder="Chọn trình độ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.includes(input)
                  }
                  allowClear
                  onChange={handleTrinhDoSelectChange}
                >
                  {trinhDoList.map((trinhDo) => (
                    <Option key={trinhDo} value={trinhDo}>
                      {trinhDo}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="filter-radio-mobile">
                <div className="filter-radio-mobile-item mb-2">
                  {["nữ", "nam"].map((item, index) => {
                    return (
                      <Checkbox
                        key={index}
                        className="option"
                        checked={item === valueGioiTinh}
                        value={item}
                        onChange={handleGioiTinhChange}
                      >
                        <FontAwesomeIcon
                          icon={item === "nam" ? faMars : faVenus}
                          color={item === "nam" ? "#02a2ff" : "#ec48a7"}
                        />
                        <span className="ml-2">Gia sư {item}</span>
                      </Checkbox>
                    );
                  })}
                </div>
              </div>

              <div className="active-filters-container my-1 overflow-hidden text-left">
                {Object.keys(filterList).map((key, index) => {
                  if (typeof filterList[key] === "string") {
                    return (
                      <Button
                        key={index}
                        className="px-3 bg-white rounded-full text-gray-600 m-1 text-sm"
                        onClick={() => handleClearTag(key)}
                      >
                        {filterList[key]} x
                      </Button>
                    );
                  }

                  return null;
                })}
              </div>
            </div>

            <div className="lg:ml-6 w-full relative flex flex-col">
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  onChange: handleChangePagination,
                  pageSize: window.innerWidth > 768 ? 10 : 5,
                  hideOnSinglePage: true,
                }}
                dataSource={tutorListShuffle}
                header={
                  <div className="header-total">
                    <p>{tutors?.length || 0} gia sư</p>
                    <div className="modal-filter-button lg:hidden">
                      <FontAwesomeIcon
                        className="p-4"
                        icon={faFilter}
                        onClick={showModal}
                      />
                      <Modal
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={[
                          <button
                            key="filter"
                            className="bg-blue-900 text-white text-base py-2 w-full"
                            onClick={handleOk}
                          >
                            Tìm kiếm
                          </button>,
                        ]}
                      >
                        <FilterComponent role="tutor" inputKey={inputKey} />

                        <div className="p-4 pt-0 lg:p-0 lg:hidden">
                          <span className="lg:mt-6 mb-2 block font-medium">
                            Không tìm thấy gia sư bạn cần?
                          </span>
                          <Link
                            className="text-xs lg:text-base bg-blue-900 border-blue-900 border px-2 md:px-3 py-2 rounded-md text-white"
                            to={`/${SIGN_UP}`}
                          >
                            + Đăng ký tìm gia sư mới
                          </Link>
                        </div>
                      </Modal>
                    </div>
                  </div>
                }
                renderItem={(tutor) => {
                  return <TutorList tutor={tutor} />;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindTutors;
