import Swal from "sweetalert2";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";

import * as types from "./types";
import { database } from "~/firebase";

const tutorsCollectionRef = collection(database, "tutors");
const studentsContactRef = (sdt, role) =>
  doc(database, "contactTutor", sdt, "role", role);

export const getListTutors = () => {
  return async (dispatch) => {
    try {
      const tutors = (await getDocs(tutorsCollectionRef)).docs
        .map((item) => item.data())
        .sort((a, b) => b.maGiaSu - a.maGiaSu);

      //Sau khi lấy dữ liệu từ firebase về => redux (reducer)
      dispatch({
        type: types.GET_LIST_TUTORS,
        payload: tutors,
      });
    } catch (errors) {
      console.log(errors);
    }
  };
};

export const contactTutorAction = (sdt, role, data) => {
  return async () => {
    try {
      await setDoc(studentsContactRef(sdt, role), data);

      Swal.fire(
        "Đã nhận thông tin!",
        "Trung tâm sẽ liên hệ với bạn sớm nhất!",
        "success"
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        Swal.fire({
          title: "Có lỗi!?!",
          icon: "error",
          html:
            "Bạn vui lòng liên hệ " +
            '<a href="https://m.me/giasumantiep">trung tâm.</a>' +
            " Xin cảm ơn!",
        });
        console.log("errorFirebase", error);
      }
    }
  };
};
