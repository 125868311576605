import { memo } from "react";
import icon05 from "~/assets/svg/choose us/IconSet-05.svg";
import icon07 from "~/assets/svg/choose us/IconSet-07.svg";
import icon18 from "~/assets/svg/choose us/IconSet-18.svg";
import icon25 from "~/assets/svg/choose us/IconSet-25.svg";
import icon27 from "~/assets/svg/choose us/IconSet-27.svg";
import icon28 from "~/assets/svg/choose us/IconSet-28.svg";

function HomeChooseUs() {
  const cards = [
    {
      image: icon18,
      title: "Gia sư có đủ điều kiện",
      paragraph:
        "Tất cả các gia sư đều là giáo viên có năng lực, tốt nghiệp đại học (có bằng cấp liên quan) hoặc sinh viên đại học đang theo học các ngành liên quan.",
    },
    {
      image: icon25,
      title: "Vì sự tiến bộ của người học",
      paragraph:
        "Mỗi học sinh có một chương trình được điều chỉnh riêng để đảm bảo việc học tập tốt lên từng ngày.",
    },
    {
      image: icon27,
      title: "Sẵn sàng dạy và học trực tuyến",
      paragraph:
        "Không thể học trực tiếp được? Đừng lo lắng, hầu hết gia sư của chúng tôi đều có khả năng dạy trực tuyến.",
    },
    {
      image: icon07,
      title: "Gia sư đã được kiểm tra",
      paragraph:
        "Về nền tảng học vấn, trình độ kỹ năng và kinh nghiệm, tính cách, cách thức, động lực và độ tin cậy của họ.",
    },
    {
      image: icon05,
      title: "Môi trường học do bạn lựa chọn",
      paragraph:
        "Thuận tiện học tại nhà riêng của bạn, trực tuyến hay tại một địa điểm đã thỏa thuận với gia sư từ trước.",
    },
    {
      image: icon28,
      title: "Hỏi đáp cùng gia sư",
      paragraph:
        "Bên cạnh kiến thức chuyên môn, gia sư chúng tôi còn chia sẻ kiến thức về nhiều khía cạnh khác nhau như tâm lí, giáo dục...",
    },
  ];

  return (
    <div className="container text-center py-16 px-5">
      <div className="title mb-8">
        <h1 className="text-3xl">Lý do bạn chọn Gia sư Mẫn Tiệp</h1>
        <p>Có 6 lý do bạn nên chọn chúng tôi</p>
      </div>

      <div className="icon-panel">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {cards.map((item, index) => (
            <div className="content" key={index}>
              <img src={item.image} alt="icon" className="w-1/3" />
              <h2 className="icon">{item.title}</h2>
              <p>{item.paragraph}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(HomeChooseUs);
