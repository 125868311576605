import {
  faBook,
  faBookAtlas,
  faMars,
  faMountainCity,
  faPersonDotsFromLine,
  faSchoolFlag,
  faTransgender,
  faUserCheck,
  faUserGraduate,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";

import ButtonContactTutor from "./ButtonContactTutor";

const TutorList = ({ tutor }) => {
  const {
    maGiaSu,
    sdt,
    email,
    anhDaiDien,
    gioiTinh,
    hoTen,
    ngaySinh,
    trinhDo,
    nganhHoc,
    truongDaoTao,
    lopDay,
    monDay,
    quanHuyenDay,
    tinhThanhDay,
    uuDiem,
  } = tutor;

  const renderArrayField = (array) => {
    return array?.map((item, index) => {
      const length = array?.length;
      if (index < length - 1) {
        return `${item}, `;
      } else {
        return item;
      }
    });
  };

  return (
    <div className="search-result">
      <div className="flex">
        <div className="image-holder">
          <img
            src={
              anhDaiDien.length ? anhDaiDien : "https://picsum.photos/200/200"
            }
            className="profile-image"
            alt="Ảnh đại diện"
            loading="lazy"
            width={125}
            height={125}
          />
        </div>

        <div className="personal-info">
          <h3 className="text-lg md:text-2xl">
            {hoTen} - <span className="font-normal text-lg">{ngaySinh}</span>
          </h3>

          <div className="text-base">
            <p className="item">
              <FontAwesomeIcon icon={faUserGraduate} />
              <span className="font-semibold ml-1">Hiện là: </span>
              {trinhDo}{" "}
              <FontAwesomeIcon
                icon={
                  gioiTinh === "Nam"
                    ? faMars
                    : gioiTinh === "Nữ"
                    ? faVenus
                    : faTransgender
                }
                color={
                  gioiTinh === "Nam"
                    ? "#02a2ff"
                    : gioiTinh === "Nữ"
                    ? "#ec48a7"
                    : "#6f42c1"
                }
              />
            </p>
            <p className="item">
              <FontAwesomeIcon icon={faBook} />
              <span className="font-semibold ml-1">Chuyên ngành: </span>
              {nganhHoc}
            </p>
            <p className="item">
              <FontAwesomeIcon icon={faSchoolFlag} />
              <span className="font-semibold ml-1">Trường: </span>
              {truongDaoTao}
            </p>
          </div>
        </div>
      </div>

      <div className="tutor-info">
        <p className="item">
          <FontAwesomeIcon icon={faPersonDotsFromLine} />
          <span>Lớp dạy:</span> {renderArrayField(lopDay)}
        </p>
        <p className="item">
          <FontAwesomeIcon icon={faBookAtlas} />
          <span>Môn dạy:</span> {renderArrayField(monDay)}
        </p>
        <p className="item">
          <FontAwesomeIcon icon={faMountainCity} />
          <span>Khu vực:</span> {renderArrayField(quanHuyenDay)} -{" "}
          {tinhThanhDay}
        </p>
      </div>

      <div className="description">{uuDiem}</div>

      <div className="contact">
        <Tooltip
          placement="bottomLeft"
          title="Đã xác minh"
          overlayStyle={{ borderRadius: "8px" }}
        >
          <FontAwesomeIcon
            className="verify"
            icon={faUserCheck}
            color="#3f9d3c"
          />
        </Tooltip>

        <ButtonContactTutor tutor={{ maGiaSu, sdt, email }} />
      </div>
    </div>
  );
};

export default TutorList;
