import { memo } from "react";
import step1 from "~/assets/svg/process/step1.svg";
import step2 from "~/assets/svg/process/step2.svg";
import step3 from "~/assets/svg/process/step3.svg";
import step4 from "~/assets/svg/process/step4.svg";
import "./HomeProcess.scss";

function HomeProcess() {
  return (
    <div className="process">
      <div className="title">
        <h1 className="text-3xl">Đặt lớp như thế nào?</h1>
        <p>Rất dễ dàng cho mọi người</p>
      </div>

      <section className="ps-timeline-sec">
        <div className="container">
          <ol className="ps-timeline">
            <li>
              <div className="img-handler-top">
                <img src={step1} alt="" />
              </div>
              <div className="ps-bot">
                <h2>Đăng ký lớp học</h2>
                <p>
                  Điền thông tin chi tiết về môn học hoặc liên hệ trực tiếp với
                  chúng tôi.
                </p>
                <a
                  className="text-blue-900 underline font-medium"
                  target="_blank"
                  rel="noreferrer"
                  href="https://m.me/giasumantiep"
                >
                  Bấm đăng ký lớp học ngay
                </a>
              </div>
              <span className="ps-sp-top">01</span>
            </li>
            <li>
              <div className="img-handler-bot">
                <img src={step2} alt="" />
              </div>
              <div className="ps-top">
                <h2>Chúng tôi sẽ liên hệ với bạn</h2>
                <p>
                  Sau khi đồng ý về học phí và thời gian hợp lý. Sẽ có gia sư
                  phù hợp với các yêu cầu của bạn.
                </p>
              </div>
              <span className="ps-sp-bot">02</span>
            </li>
            <li>
              <div className="img-handler-top">
                <img src={step3} alt="" />
              </div>
              <div className="ps-bot">
                <h2>Học tập</h2>
                <p>
                  Bắt đầu các bài học trực tiếp hoặc trực tuyến với gia sư của
                  bạn và tận hưởng sự tiến bộ bạn đạt được trong môn học mong
                  muốn.
                </p>
              </div>
              <span className="ps-sp-top">03</span>
            </li>
            <li>
              <div className="img-handler-bot">
                <img src={step4} alt="" />
              </div>
              <div className="ps-top">
                <h2>Đánh giá và chia sẻ</h2>
                <p>
                  Chia sẻ ngay cảm xúc của bạn về gia sư với{" "}
                  <a
                    className="text-blue-900"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/giasumantiep"
                  >
                    trung tâm
                  </a>{" "}
                  nhé!
                </p>
              </div>
              <span className="ps-sp-bot">04</span>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}

export default memo(HomeProcess);
