import "./styles.scss";

const Spinner = () => {
  return (
    <div className="spinner_container">
      <div className="lds-hourglass"></div>
    </div>
  );
};

export default Spinner;
