export const alphabetsOnly = {
  pattern: new RegExp(
    /^[a-zA-ZàáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ ,]*$/
  ),
  message: "Chỉ nhập kí tự alphabets!",
};

export const validateSdt = [
  {
    min: 10,
    max: 10,
    pattern: new RegExp(/(0[2-9])+([0-9]{8})\b/),
    message: "Số điện thoại chưa đúng!",
  },
  {
    required: true,
    message: "Vui lòng nhập số điện thoại!",
  },
];
export const validateHoTen = [
  alphabetsOnly,
  {
    max: 50,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập họ tên!",
  },
];
export const validateMonHoc = [
  {
    required: true,
    message: "Vui lòng nhập môn học!",
  },
];
export const validateLopHoc = [
  {
    required: true,
    message: "Vui lòng nhập lớp học!",
  },
];
export const validateTinhTP = [
  { required: true, message: "Vui lòng chọn tỉnh/thành!" },
];
export const validateQuanHuyen = [
  { required: true, message: "Vui lòng chọn quận/huyện!" },
];
export const validatePhuongXa = [
  { required: true, message: "Vui lòng chọn phường/xã!" },
];
export const validateDiaChi = [
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập địa chỉ!",
  },
];
export const validateSoBuoi = [
  {
    required: true,
    message: "Vui lòng nhập số buổi!",
  },
];
export const validateSoPhut = [
  {
    required: true,
    message: "Vui lòng nhập số phút!",
  },
];
export const validateHocPhi = [
  {
    required: true,
    message: "Vui lòng nhập học phí!",
  },
];
export const validateThoiGian = [
  {
    required: true,
    message: "Vui lòng nhập thời gian!",
  },
];
export const validateThongTin = [
  {
    required: true,
    message: "Vui lòng nhập thông tin!",
  },
];
export const validateYeuCau = [
  {
    required: true,
    message: "Vui lòng nhập thông tin!",
  },
];
export const validateMatKhau = [
  {
    min: 6,
    message: "Mật khẩu ít nhất 6 kí tự!",
  },
  {
    required: true,
    message: "Vui lòng nhập mật khẩu!",
  },
];
export const validateXacNhanMatKhau = [
  {
    required: true,
    message: "Vui lòng nhập lại mật khẩu!",
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("matKhau") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Mật khẩu chưa khớp!"));
    },
  }),
];
export const validateNgaySinh = [
  {
    required: true,
    message: "Vui lòng nhập ngày sinh!",
  },
];
export const validateGioiTinh = [
  {
    required: true,
    message: "Vui lòng nhập giới tính!",
  },
];
export const validateNguyenQuan = [
  {
    max: 100,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập nguyên quán!",
  },
];
export const validateDiaChiHienTai = [
  {
    max: 100,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập địa chỉ hiện tại!",
  },
];
export const validateSoCCCD = [
  {
    max: 12,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập số CMND/CCCD!",
  },
];
export const validateEmail = [
  {
    type: "email",
    message: "E-mail chưa đúng định dạng!",
  },
  {
    required: true,
    message: "Vui lòng nhập email!",
  },
];
export const validateTruongDaoTao = [
  {
    max: 100,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập trường đào tạo!",
  },
];
export const validateNganhHoc = [
  {
    max: 50,
    message: "Bạn nhập quá dài!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập ngành học!",
  },
];
export const validateTrinhDo = [
  { required: true, message: "Vui lòng chọn trình độ!" },
];
export const validateMonDay = [
  {
    required: true,
    message: "Vui lòng nhập môn dạy!",
  },
];
export const validateLopDay = [
  {
    required: true,
    message: "Vui lòng nhập lớp dạy!",
  },
];
export const validateKhuVucDay = [
  {
    required: true,
    message: "Vui lòng nhập khu vực dạy!",
  },
];
export const validateUuDiem = [
  {
    max: 500,
    message: "Vui lòng nhập không quá 500 ký tự!",
  },
  {
    whitespace: true,
    message: "Ô nhập chỉ có khoảng trắng!",
  },
  {
    required: true,
    message: "Vui lòng nhập ưu điểm!",
  },
];
