export const monHocList = [
  "Toán",
  "Lý",
  "Hóa",
  "Văn",
  "Tiếng Việt",
  "Anh Văn",
  "Báo Bài",
  "Sinh",
  "Sử",
  "Địa",
  "Tin Học",
  "Vẽ",
  "Rèn Chữ",
  "Anh Văn Giao Tiếp",
  "TOEIC",
  "IELTS",
  "TOEFL",
  "Tiếng Pháp",
  "Tiếng Hàn",
  "Tiếng Hoa",
  "Tiếng Nhật",
  "Đàn Piano",
  "Đàn Organ",
  "Đàn Guitar",
  "Tiếng Việt Cho Người Nước Ngoài",
  "Khoa Học Tự Nhiên",
  "Môn học khác",
];

export const lopHocList = [
  "Lớp Lá",
  "Lớp 1",
  "Lớp 2",
  "Lớp 3",
  "Lớp 4",
  "Lớp 5",
  "Lớp 6",
  "Lớp 7",
  "Lớp 8",
  "Lớp 9",
  "Lớp 10",
  "Lớp 11",
  "Lớp 12",
  "Ôn Thi Đại Học",
  "Dạy Song Ngữ",
  "Ôn Thi Trường Chuyên",
  "Dạy Trẻ Tự Kỷ",
  "Các Lớp Khác",
];

export const trinhDoList = [
  "Giảng viên",
  "Giáo viên",
  "Sinh viên sư phạm",
  "Sinh viên",
  "Cử nhân",
  "Kĩ sư",
  "Thạc sĩ",
  "Tiến sĩ",
  "Bằng cấp khác",
];
