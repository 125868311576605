import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import Swal from "sweetalert2";

import * as types from "./types";
import { database } from "~/firebase";
import { FirebaseError } from "firebase/app";

const studentsCollectionRef = collection(database, "students");
const tutorRef = (sdt) => doc(database, "tutors", sdt);

export const getListStudents = () => {
  return async (dispatch) => {
    try {
      const students = (await getDocs(studentsCollectionRef)).docs
        .map((item) => item.data())
        .sort(
          (a, b) => Number(a.daGiao) - Number(b.daGiao) || b.maLop - a.maLop
        );

      //Sau khi lấy dữ liệu từ firebase về => redux (reducer)
      dispatch({
        type: types.GET_LIST_STUDENTS,
        payload: students,
      });
    } catch (errors) {
      console.log(errors);
    }
  };
};

export const editProfileStart = () => ({
  type: types.EDIT_PROFILE_START,
});

const editProfileSuccess = (user) => ({
  type: types.EDIT_PROFILE_SUCCESS,
  payload: user,
});

export const editProfileFail = (error) => ({
  type: types.EDIT_PROFILE_FAIL,
  payload: error,
});

export const editProfileAction = (data) => {
  return async (dispatch) => {
    try {
      await updateDoc(tutorRef(data.sdt), data);

      dispatch(editProfileSuccess(data));
      Swal.fire("Thay đổi thành công!", "Chúc bạn dạy tốt!", "success");
    } catch (error) {
      if (error instanceof FirebaseError) {
        dispatch(editProfileFail(error.code));
        Swal.fire({
          title: "Có lỗi...",
          icon: "error",
          html:
            "Bạn vui lòng liên hệ " +
            '<a href="https://m.me/giasumantiep">trung tâm</a>' +
            " xin cảm ơn!",
        });
        console.log("errorFirebase", error);
      }
    }
  };
};

export const takeClassAction = (sdt, data) => {
  return async () => {
    try {
      await updateDoc(tutorRef(sdt), data);

      Swal.fire(
        "Nhận lớp thành công!",
        "Trung tâm sẽ liên hệ với bạn nếu lớp phù hợp!",
        "success"
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        Swal.fire({
          title: "Có lỗi!?!",
          icon: "error",
          html:
            `${error.code}` +
            "<br/>" +
            "Bạn vui lòng liên hệ " +
            '<a href="https://m.me/giasumantiep">trung tâm.</a>' +
            " Xin cảm ơn!",
        });
        console.log("errorFirebase", error);
      }
    }
  };
};
