import {
  faChalkboardUser,
  faMars,
  faPersonChalkboard,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { cityData, provinceData } from "~/utils/data/countryData";
import { lopHocList, monHocList, trinhDoList } from "~/utils/data/infoData";
import {
  clearFilter,
  gioiTinhFilterChange,
  lopHocFilterChange,
  maLopFilterChange,
  monHocFilterChange,
  quanHuyenFilterChange,
  tinhThanhFilterChange,
  trinhDoFilterChange,
} from "./redux/actions";
import "./styles.scss";

const { Search } = Input;
const { Option } = Select;

const FilterComponent = ({ role, inputKey }) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState(undefined);
  const [valueGioiTinh, setValueGioiTinh] = useState("");
  const [valueTrinhDo, setValueTrinhDo] = useState("");

  const [form] = Form.useForm();

  const handleClearFilter = () => {
    form.resetFields();
    setCities(undefined);
    setValueGioiTinh("");
    setValueTrinhDo("");
    dispatch(clearFilter());
  };

  useEffect(() => {
    handleClearFilter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue({ [inputKey]: undefined });
    if (inputKey === "tinhThanh") {
      form.setFieldsValue({ quanHuyen: undefined });
    }
  }, [form, inputKey]);

  const handleMaLopChange = (e) => {
    dispatch(maLopFilterChange(e.target.value));
  };

  const handleMonHocChange = (value) => {
    dispatch(monHocFilterChange(value));
  };

  const handleLopHocChange = (value) => {
    dispatch(lopHocFilterChange(value));
  };

  const handleTrinhDoSelectChange = (value) => {
    dispatch(trinhDoFilterChange([value]));
  };

  const handleGioiTinhChange = (checkedValues) => {
    const { value } = checkedValues.target;
    if (valueGioiTinh === value) {
      setValueGioiTinh("");
      dispatch(gioiTinhFilterChange([]));
    } else {
      setValueGioiTinh(value);
      dispatch(gioiTinhFilterChange([value]));
    }
  };

  const handleTrinhDoRadioChange = (checkedValues) => {
    const { value } = checkedValues.target;
    if (valueTrinhDo === value) {
      setValueTrinhDo("");
      dispatch(trinhDoFilterChange([]));
    } else {
      setValueTrinhDo(value);
      dispatch(trinhDoFilterChange([value]));
    }
  };

  const handleProvinceChange = (value) => {
    const provinceLabel = provinceData.find(
      (val) => val.value === value
    )?.label;
    if (value) {
      setCities(cityData[value]);
      form.setFieldsValue({ tinhThanh: provinceLabel });
      // form.setFieldsValue({ quanHuyen: cityData[value][0].label });
    } else {
      setCities(undefined);
    }
    form.setFieldsValue({ quanHuyen: undefined });
    dispatch(tinhThanhFilterChange(provinceLabel));
    dispatch(quanHuyenFilterChange(undefined));
  };

  const handleCityChange = (value) => {
    const cityLabel = cities.find((val) => val.value === value)?.label;
    if (value) {
      form.setFieldsValue({ quanHuyen: cityLabel });
    }
    dispatch(quanHuyenFilterChange(cityLabel));
  };

  const handleFilter = (values) => {
    console.log("Để cho đẹp thôi!");
  };

  return (
    <div className="sticky-filter">
      <div className="filters-container flex flex-col">
        <div className="filters">
          <div className="filter-heading filter-item my-4 pb-4">
            <p className="text-xl font-medium">Tìm kiếm</p>
            <button
              className="border-0 bg-white underline text-base font-medium"
              onClick={handleClearFilter}
            >
              Xóa bộ lọc
            </button>
          </div>

          <Form form={form} onFinish={handleFilter}>
            {role === "student" && (
              <div className="filter-item">
                <div className="filter-item-title">Mã lớp</div>
                <Form.Item className="my-2" name="maLop">
                  <Search
                    placeholder="Nhập mã lớp"
                    allowClear
                    // onSearch={(value) => console.log(value)}
                    onChange={handleMaLopChange}
                    maxLength={5}
                  />
                </Form.Item>
              </div>
            )}

            <div className="filter-item">
              <div className="filter-item-title">Môn học</div>
              <Form.Item className="my-2" name="monHoc">
                <Select
                  showSearch
                  placeholder="Chọn môn học"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input)
                  }
                  allowClear
                  onChange={handleMonHocChange}
                >
                  {monHocList.map((mon) => (
                    <Option key={mon} value={mon}>
                      {mon}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="filter-item">
              <div className="filter-item-title">Lớp học</div>
              <Form.Item className="my-2" name="lopHoc">
                <Select
                  showSearch
                  placeholder="Chọn lớp học"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.includes(input)
                  }
                  allowClear
                  onChange={handleLopHocChange}
                >
                  {lopHocList.map((lop) => (
                    <Option key={lop} value={lop}>
                      {lop}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="my-4 filter-item hidden lg:block">
              <div className="filter-item-title font-medium">Giới tính</div>
              <Form.Item className="tutoring-radio-container my-1">
                {["nữ", "nam"].map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      className="option"
                      checked={item === valueGioiTinh}
                      value={item}
                      onChange={handleGioiTinhChange}
                    >
                      <FontAwesomeIcon
                        icon={item === "nam" ? faMars : faVenus}
                        color={item === "nam" ? "#02a2ff" : "#ec48a7"}
                      />
                      <span className="ml-2">Gia sư {item}</span>
                    </Checkbox>
                  );
                })}
              </Form.Item>
            </div>

            <div className="my-4 filter-item hidden lg:block">
              <div className="filter-item-title font-medium">Trình độ</div>
              {role === "student" ? (
                <Form.Item className="tutoring-radio-container my-1">
                  {["Giáo viên", "Sinh viên"].map((item, index) => {
                    return (
                      <Checkbox
                        key={index}
                        className="option"
                        checked={item === valueTrinhDo}
                        value={item}
                        onChange={handleTrinhDoRadioChange}
                      >
                        <FontAwesomeIcon
                          icon={
                            item === "Sinh viên"
                              ? faChalkboardUser
                              : faPersonChalkboard
                          }
                          color={item === "Sinh viên" ? "#8dee91" : "#ff7b76"}
                        />
                        <span className="ml-2">
                          {item === "Sinh viên" ? "Sinh viên" : "Giáo viên"}
                        </span>
                      </Checkbox>
                    );
                  })}
                </Form.Item>
              ) : (
                <Form.Item className="my-2" name="trinhDo">
                  <Select
                    showSearch
                    placeholder="Chọn trình độ"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    allowClear
                    onChange={handleTrinhDoSelectChange}
                  >
                    {trinhDoList.map((trinhDo) => (
                      <Option key={trinhDo} value={trinhDo}>
                        {trinhDo}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>

            <div className="filter-item">
              <div className="filter-item-title">Tỉnh/Thành</div>
              <Form.Item className="my-2" name="tinhThanh">
                <Select
                  placeholder="Chọn Tỉnh/Thành"
                  onChange={handleProvinceChange}
                  options={provinceData}
                  allowClear
                />
              </Form.Item>
            </div>

            <div className="filter-item">
              <div className="filter-item-title">Quận/Huyện</div>
              <Form.Item className="my-2" name="quanHuyen">
                <Select
                  showSearch
                  placeholder="Chọn Quận/Huyện"
                  onChange={handleCityChange}
                  options={cities}
                  allowClear
                />
              </Form.Item>
            </div>

            <hr className="separator lg:my-6 hidden lg:block" />

            <div className="text-center mb-6 mx-6 hidden lg:block">
              <button
                className="w-full py-2 text-base hover:text-white hover:bg-blue-900"
                onClick={handleFilter}
              >
                Tìm kiếm
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
