import { memo } from "react";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import zaloIcon from "~/assets/img/icon-zalo.png";
import { TEL_NUMBER_2 } from "~/utils/constants/constants";

import "./styles.scss";

const ContactIcon = () => {
  return (
    <div className="contact-icon">
      <div className="pulse">
        <a className="flex" href={`tel:${TEL_NUMBER_2}`}>
          <FontAwesomeIcon
            className="phone-icon w-5 h-5 p-2 sm:w-7 sm:h-7 sm:p-4"
            icon={faPhone}
          />
        </a>
        <span className="animate-icon sm:animate-ping animate-none"></span>
      </div>

      <a
        className="ml-3"
        target="_blank"
        rel="noreferrer"
        href={`https://zalo.me/${TEL_NUMBER_2}`}
      >
        <img
          className="zalo-icon w-11 h-11 sm:w-15 sm:h-15 "
          src={zaloIcon}
          alt="zalo"
        />
      </a>
    </div>
  );
};

export default memo(ContactIcon);
