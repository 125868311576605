export const tuitionTable = [
  {
    soBuoi: 2,
    cap1: {
      sinhVien: "1.000.000 - 1.200.000",
      giaoVien: "1.600.000 - 2.000.000",
    },
    cap2: {
      sinhVien: "1.100.000 - 1.400.000",
      giaoVien: "1.600.000 - 2.200.000",
    },
    cap3: {
      sinhVien: "1.200.000 - 1.800.000",
      giaoVien: "1.800.000 - 2.400.000",
    },
    ltdh: {
      sinhVien: "1.500.000 - 2.000.000",
      giaoVien: "2.000.000 - 2.500.000",
    },
  },
  {
    soBuoi: 3,
    cap1: {
      sinhVien: "1.300.000 - 1.500.000",
      giaoVien: "2.000.000 - 2.500.000",
    },
    cap2: {
      sinhVien: "1.400.000 - 1.800.000",
      giaoVien: "2.100.000 - 2.600.000",
    },
    cap3: {
      sinhVien: "1.600.000 - 2.000.000",
      giaoVien: "2.300.000 - 3.000.000",
    },
    ltdh: {
      sinhVien: "1.800.000 - 2.500.000",
      giaoVien: "2.800.000 - 3.500.000",
    },
  },
  {
    soBuoi: 4,
    cap1: {
      sinhVien: "1.500.000 - 2.000.000",
      giaoVien: "2.400.000 - 3.000.000",
    },
    cap2: {
      sinhVien: "1.600.000 - 2.200.000",
      giaoVien: "2.500.000 - 3.000.000",
    },
    cap3: {
      sinhVien: "2.000.000 - 2.500.000",
      giaoVien: "2.800.000 - 4.000.000",
    },
    ltdh: {
      sinhVien: "2.400.000 - 2.800.000",
      giaoVien: "3.500.000 - 4.000.000",
    },
  },
  {
    soBuoi: 5,
    cap1: {
      sinhVien: "2.000.000 - 2.500.000",
      giaoVien: "3.000.000 - 3.500.000",
    },
    cap2: {
      sinhVien: "2.200.000 - 2.700.000",
      giaoVien: "3.000.000 - 3.500.000",
    },
    cap3: {
      sinhVien: "2.300.000 - 3.000.000",
      giaoVien: "3.500.000 - 4.000.000",
    },
    ltdh: {
      sinhVien: "2.700.000 - 3.000.000",
      giaoVien: "3.500.000 - 4.500.000",
    },
  },
];
