import * as types from "./types";

const initialState = {
  data: [
    {
      daGiao: true,
      diaChi: "123 Hà Huy Tôn, phường 3, Quận 6, TPHCM",
      gioiTinh: "Nu",
      hoTen: "Nguyễn Hi Ton",
      hocPhi: "200000",
      lopHoc: "12",
      maGiaSu: "123456",
      monHoc: "Toan",
      nguoiDung: "nguoiHoc",
      phanTramPhi: "30",
      phiGiaSu: "300000",
      phuongXa: "Phuong 1",
      quanHuyen: "Quan 2",
      sdt: "32222111",
      soBuoi: "3",
      soPhut: "90",
      maLop: "12387",
      thoiGian: "22h00 thứ 2, 4, 6",
      thongTin: "Học sinh nam lớn tuổi",
      tinhTP: "HCM",
      xacNhan: false,
      yeuCau: "Sinh viên nữ",
    },
  ],
  loading: false,
  error: undefined,
  currentUser: null,
};

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_STUDENTS:
      return {
        ...state,
        data: action.payload,
      };
    case types.EDIT_PROFILE_START:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      };
    case types.EDIT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default studentsReducer;
