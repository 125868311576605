import * as types from "./types";
const initialState = {
  data: [
    {
      anhBangCap: "",
      anhCCCD: "",
      anhDaiDien: "",
      diaChi: "15 Nguyễn Văn Ba, HCM",
      email: "babon@gmail.com",
      gioiTinh: "Nam",
      giongNoi: "Giọng miền Nam",
      hienLa: "Giáo viên",
      hoTen: "Mai Hồng Hà",
      khuVucDay: "Quận 1, Quận 2",
      lopDay: "10, 11, 12",
      maLop: "2,5,1,12",
      matKhau: "123456",
      monDay: "Toán, Lý, Hóa",
      namTotNghiep: "2014",
      nganhHoc: "Toán học",
      ngaySinh: "11/02/1999",
      nguoiDung: "giaSu",
      nguyenQuan: "Nhơn Hải, Quy Nhơn",
      noiDay: "HCM",
      sdt: "0123456789",
      soCCCD: "123654987",
      stt: 1,
      thoiGianDay: "Tối 2, 4, 6",
      truongHoc: "Đại học Sư phạm HCM",
      uuDiem:
        "8 năm kinh nghiệm trong đứng lớp và gia sư, khả năng lấy lại căn bản cho học sinh yếu, Luyện thi Tuyển sinh lớp 10 và Luyện thi Đại học.",
      vaiTro: "admin",
    },
  ],
  loading: false,
  error: undefined,
};

const tutorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_TUTORS:
      return {
        ...state,
        data: action.payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default tutorsReducer;
