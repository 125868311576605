import { useMemo } from "react";

const throttle = (cb, delay) => {
  let shouldwait = false;
  let incomingArgs = null;

  const scheduleNextProcess = () => {
    shouldwait = false;
    if (incomingArgs) {
      cb(...incomingArgs);
      incomingArgs = null;
      setTimeout(scheduleNextProcess, delay);
    }
  };

  return (...args) => {
    if (shouldwait) {
      incomingArgs = args;
      return;
    }

    cb(...args);
    shouldwait = true;
    setTimeout(scheduleNextProcess, delay);
  };
};

export const useThrottle = (cb, time = 0) => {
  return useMemo(
    () => {
      return throttle(cb, time);
    },
    // eslint-disable-next-line
    []
  );
};
