import { Button, Form, Input, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";

import FloatLabel from "~/components/FloatLabels/FloatLabel";
import ReCapcha from "~/components/ReCapcha";
import { useFocus, useThrottle, useTrapFocus } from "~/hooks";
import { contactTutorAction } from "~/pages/Students/redux/actions";
import {
  validateGioiTinh,
  validateHoTen,
  validateSdt,
} from "~/utils/validations/validationsInput";
import { signUpActionShortForm } from "../../redux/actions";

const { Option } = Select;

const ShortForm = (props) => {
  const { error, tutor } = props;
  const [student, setStudent] = useState({
    sdt: "",
    hoTen: "",
    gioiTinh: "",
  });
  const { sdt, hoTen, gioiTinh } = student;
  const [isCheckRecaptcha, setIsCheckRecaptcha] = useState(undefined);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setStudent((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s+/g, " ").trim(),
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const handleSelectChange = (value) => {
    setStudent((prevState) => ({
      ...prevState,
      gioiTinh: value,
    }));
  };

  const onFinishShortForm = async (values) => {
    if (isCheckRecaptcha) {
      if (error) {
        return;
      } else {
        form.resetFields();
      }

      if (tutor) {
        dispatch(contactTutorAction(values.sdt, "student", values));
        dispatch(contactTutorAction(values.sdt, "tutor", tutor));
      } else {
        dispatch(signUpActionShortForm(values));
      }
    } else {
      setIsCheckRecaptcha(false);
    }
  };

  const handleCheckRecaptcha = (value) => {
    setIsCheckRecaptcha(true);
  };

  useFocus("sdt");
  useTrapFocus("sign-up-students-short-form");

  return (
    <Form
      id="sign-up-students-short-form"
      form={form}
      name="registerShortForm"
      onFinish={onFinishShortForm}
      scrollToFirstError
    >
      <p className="text-base font-medium my-4">Thông tin đăng ký</p>

      <FloatLabel label="Số điện thoại" name="sdt" value={sdt}>
        <Form.Item name="sdt" rules={validateSdt}>
          <Input
            id="sdt"
            placeholder={sdt}
            value={sdt}
            name="sdt"
            onChange={handleInputThrottled}
            maxLength={10}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Họ tên" name="hoTen" value={hoTen}>
        <Form.Item name="hoTen" rules={validateHoTen}>
          <Input
            placeholder={hoTen}
            value={hoTen}
            name="hoTen"
            onChange={handleInputThrottled}
            allowClear
          />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label="Giới tính" name="gioiTinh" value={gioiTinh}>
        <Form.Item name="gioiTinh" rules={validateGioiTinh}>
          <Select
            style={{ width: "100%" }}
            name="gioiTinh"
            onChange={handleSelectChange}
            value={gioiTinh}
          >
            <Option value="Nam">Nam</Option>
            <Option value="Nữ">Nữ</Option>
            <Option value="Khác">Khác</Option>
          </Select>
        </Form.Item>
      </FloatLabel>

      <ReCapcha
        isCheckRecaptcha={isCheckRecaptcha}
        handleCheckRecaptcha={handleCheckRecaptcha}
      />

      <Form.Item>
        <Button className="button-form mt-5" htmlType="submit">
          Đăng ký
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ShortForm;
