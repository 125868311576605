import { createSelector } from "reselect";
import {
  filterMaLopSelector,
  filterMonHocSelector,
  filterLopHocSelector,
  filterGioiTinhSelector,
  filterTrinhDoSelector,
  filterTinhThanhSelector,
  filterQuanHuyenSelector,
} from "~/components/FilterComponent/redux/selectors";

export const studentsSelector = (state) => state.studentsState.data;
export const tutorSelector = (state) => state.studentsState;
export const loginTutor = (state) => state.loginTutorState.currentUser;

export const studentsRemainingSelector = createSelector(
  studentsSelector,
  filterMaLopSelector,
  filterMonHocSelector,
  filterLopHocSelector,
  filterGioiTinhSelector,
  filterTrinhDoSelector,
  filterTinhThanhSelector,
  filterQuanHuyenSelector,
  (
    studentList,
    maLopText,
    monHocText,
    lopHocText,
    gioiTinh,
    trinhDo,
    tinhThanhText,
    quanHuyenText
  ) => {
    return studentList.filter((student) => {
      return (
        student.xacNhan &&
        (maLopText ? student.maLop.includes(maLopText) : true) &&
        (monHocText ? student.monHoc.includes(monHocText) : true) &&
        (lopHocText ? student.lopHoc.includes(lopHocText) : true) &&
        (gioiTinh.length === 1 ? student.yeuCau.includes(gioiTinh) : true) &&
        (trinhDo.length === 1 ? student.yeuCau.includes(trinhDo) : true) &&
        (tinhThanhText ? student.tinhTP.includes(tinhThanhText) : true) &&
        (quanHuyenText ? student.quanHuyen.includes(quanHuyenText) : true)
      );
    });
  }
);
