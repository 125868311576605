import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardUser,
  faMars,
  faPersonChalkboard,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
// import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
// import axios from "axios";

import { TITLE } from "~/utils/constants/constants";
import { loginTutor } from "~/pages/Tutors/redux/selectors";
import DefaultUserImg from "~/assets/img/user-profile-default.png";
import { EDIT_FROFILE, NEW_CLASS, TUTOR } from "~/utils/constants/pathUrl";
import "./styles.scss";
import { loginAction } from "~/pages/SignIn/redux/actions";

const TutorInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tutorLogin = useSelector(loginTutor);
  const {
    gioiTinh,
    trinhDo,
    lopDangKy,
    lopDaNhan,
    hoTen,
    ngaySinh,
    nguyenQuan,
    diaChiHienTai,
    nganhHoc,
    truongDaoTao,
    uuDiem,
    sdt,
    email,
    tinhThanhDay,
    quanHuyenDay,
    anhDaiDien,
    anhCCCD,
    anhBangCap,
  } = tutorLogin || {};

  useLayoutEffect(() => {
    document.title = `Thông tin cá nhân | ${TITLE}`;
  }, []);

  useEffect(() => {
    if (sdt) {
      dispatch(loginAction(sdt));
    }
  }, [sdt, dispatch]);

  // const getPublicIdFromUrl = (url) => {
  //   const match = url.match(/\/([^/]+)\.\w{3,4}$/);
  //   return match ? match[1] : null;
  // };

  // const generateSignature = (publicId, apiSecret) => {
  //   const timestamp = new Date().getTime();
  // const message = `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`;
  // return SHA1(message);
  // };

  // const handleDeleteImage = async (cloudinaryUrl) => {
  //   const publicId = getPublicIdFromUrl(cloudinaryUrl);
  //   console.log(publicId);

  // const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  // const timestamp = new Date().getTime();
  // const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY;
  // const apiSecret = process.env.REACT_APP_CLOUDINARY_API_SECRET;
  // const signature = generateSignature(publicId, apiSecret);
  // console.log("signature", signature);
  // const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`;

  // try {
  //   const response = await axios.post(url, {
  //     public_id: publicId,
  //     signature: signature,
  //     api_key: apiKey,
  //     timestamp: timestamp,
  //   });

  //   console.error(response);
  // } catch (error) {
  //   console.error(error);
  // }
  // };

  return (
    <div className="user-profile">
      <div
        className="bg-img pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage: "url(https://picsum.photos/1600/900)",
          backgroundSize: "cover",
        }}
      >
        <span className="mask bg-gradient-default opacity-60"></span>

        <div className="container-fluid d-flex align-items-center">
          <div className="row">
            <div className="col-lg-7 col-md-10">
              <h1 className="display-2 text-white">
                Hello {hoTen?.split(" ").pop()}
              </h1>
              <p className="text-white mt-0 mb-5">
                Đây là trang hồ sơ thông tin cá nhân của bạn. Bạn có thể kiểm
                tra, thay đổi thông tin và xem các lớp đã nhận.
              </p>
              <Link to={`/${TUTOR}/${NEW_CLASS}`} className="btn btn-info">
                Tìm lớp mới
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt--7 mb-28">
        <div className="row">
          <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
            <div className="card bg-secondary shadow">
              <div className="row justify-content-center">
                <div className="col-lg-3 order-lg-2">
                  <div className="card-profile-image">
                    <img
                      src={anhDaiDien?.length ? anhDaiDien : DefaultUserImg}
                      className="rounded-circle"
                      alt="rounded-circle"
                    />
                  </div>
                </div>
              </div>
              <div className="card-header text-center border-0 pt-7 pb-4">
                <div className="d-flex justify-content-between">
                  {trinhDo && (
                    <div className="btn-sm btn-info w-24">
                      <FontAwesomeIcon
                        icon={
                          trinhDo === "Sinh viên"
                            ? faChalkboardUser
                            : faPersonChalkboard
                        }
                      />
                      <span className="ml-2">
                        {trinhDo === "Sinh viên" ? "Sinh viên" : "Giáo viên"}
                      </span>
                    </div>
                  )}
                  {gioiTinh && (
                    <div className="btn-sm btn-default w-24">
                      <FontAwesomeIcon
                        icon={gioiTinh === "nam" ? faMars : faVenus}
                      />
                      <span className="ml-2">
                        {gioiTinh === "nam" ? "Nam" : "Nữ"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <span className="heading">{lopDangKy?.length}</span>
                        <span className="description">Lớp đăng ký</span>
                      </div>
                      <div>
                        <span className="heading">{lopDaNhan?.length}</span>
                        <span className="description">Lớp đã nhận</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h3>
                    {hoTen}
                    <span className="font-weight-light">, {ngaySinh}</span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2"></i>
                    {nguyenQuan}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2"></i>
                    {nganhHoc}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2"></i>
                    {truongDaoTao}
                  </div>
                  <hr className="my-4" />
                  <p>{uuDiem}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 order-xl-1">
            <div className="card bg-secondary shadow">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">Tài khoản của tôi</h3>
                  </div>
                  <div className="col-4 text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        navigate(`/${TUTOR}/${EDIT_FROFILE}`);
                      }}
                    >
                      Chỉnh sửa
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <h6 className="heading-small text-muted mb-4">
                    Thông tin tài khoản
                  </h6>
                  <div className="pl-lg-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <p className="form-control-label">Số điện thoại</p>
                          <p className="form-control form-control-alternative">
                            {sdt}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <p className="form-control-label">Địa chỉ email</p>
                          <p className="form-control form-control-alternative">
                            {email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Thông tin địa chỉ
                  </h6>
                  <div className="pl-lg-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <p className="form-control-label">Địa chi hiện tại</p>
                          <p className="form-control form-control-alternative">
                            {diaChiHienTai}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <p className="form-control-label">Tỉnh/Thành dạy</p>
                          <p className="form-control form-control-alternative">
                            {tinhThanhDay}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <p className="form-control-label">Quận/Huyện dạy</p>
                          <p className="form-control form-control-alternative">
                            {quanHuyenDay}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Bộ sưu tập</h6>
                  <div className="pl-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Ảnh CCCD</label>
                      <div className="grid grid-cols-2 gap-8">
                        {anhCCCD?.map((item, index) => (
                          <div key={index} className="px-2">
                            {/* <div className="md:w-4/5 m-auto"> */}
                            <img
                              className="rounded-md"
                              src={item}
                              alt="Ảnh CCCD"
                            />
                            {/* <div
                                className="delete-button"
                                onClick={() => handleDeleteImage(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  color="white"
                                />
                              </div> */}
                            {/* </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Ảnh bằng cấp</label>
                      <div className="grid grid-cols-2 gap-8">
                        {anhBangCap?.map((item, index) => (
                          <div key={index} className="px-2">
                            {/* <div className="m-auto"> */}
                            <img
                              className="rounded-md"
                              src={item}
                              alt="Ảnh bằng cấp"
                            />
                            {/* <div
                                className="delete-button"
                                onClick={() => handleDeleteImage(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  color="white"
                                />
                              </div> */}
                            {/* </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorInfo;
