import { memo } from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

import { FIND_TUTOR, STUDENT } from "~/utils/constants/pathUrl";

import "./styles.scss";

function HomeTutor() {
  const tutor = [
    {
      img: "https://media.istockphoto.com/photos/profile-view-of-asian-young-woman-on-street-in-downtown-picture-id1284317575?k=20&m=1284317575&s=612x612&w=0&h=OwTGqqFzg7EhGjz38PDLl9lNaCJUpzg4Db4zMgWSL2o=",
      name: "Trương Thùy Dương",
      subject: "Anh văn",
    },
    {
      img: "https://www.asianaccess.org/images/stories/authors/ginouye/gi-profile.jpg",
      name: "Bùi Văn Bá",
      subject: "Toán, Lý, Hóa",
    },
    {
      img: "https://images.unsplash.com/photo-1513097633097-329a3a64e0d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=796&q=80",
      name: "Nguyễn Hà Trinh",
      subject: "Ngữ Văn",
    },
    {
      img: "https://media.istockphoto.com/photos/portrait-of-asian-businessman-in-urban-business-district-picture-id1349320923?k=20&m=1349320923&s=612x612&w=0&h=7Z7dYYl8UcFfPbapPK4AOsMmuoyd7pEgNY02hdsKCOw=",
      name: "Nguyễn Trần Anh Khang",
      subject: "Toán, Hóa, Sinh",
    },
    {
      img: "https://media.istockphoto.com/photos/beautiful-attractive-asian-female-freshness-smile-cheerful-morning-picture-id1255707505?k=20&m=1255707505&s=612x612&w=0&h=p_gTEqEBkrsggvjo57ndYg4Qzzz7mweAjLNi3Vy9sfc=",
      name: "Trần Kiều Oanh",
      subject: "Vật Lý",
    },
    {
      img: "https://media.istockphoto.com/id/1137851574/photo/profile-of-young-womans-face.jpg?s=612x612&w=0&k=20&c=FB22Rb03sYfCbN_L2chCjl4uS7bTU7V7U9XE2HFqHZQ=",
      name: "Nguyễn Thùy Dung",
      subject: "Tiếng Nhật, Tiếng Anh",
    },
  ];

  return (
    <div className="our-tutor">
      <div className="container">
        <div className="title mb-8">
          <h1 className="text-3xl">Gặp gỡ gia sư của chúng tôi</h1>
          <p>Chúng tôi có các gia sư hàng đầu để dạy cho bạn</p>
        </div>

        <div className="tutor grid grid-cols-2 md:grid-cols-3 gap-5">
          {tutor.map((item, index) => {
            return (
              <div className="card" key={index}>
                <div className="rounded-full overflow-hidden w-20 h-20 sm:w-28 sm:h-28 mb-5  m-auto">
                  <img
                    src={item.img}
                    alt={item.name}
                    className="h-full object-cover"
                  />
                </div>
                <div className="profile">
                  <h2 className="text-sm sm:text-lg mb-2">{item.name}</h2>
                  <p className="text-xs sm:text-base font-medium text-gray-500">
                    {item.subject}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <Link className="view-all" to={`/${STUDENT}/${FIND_TUTOR}`}>
          <p className="mr-2">XEM TẤT CẢ GIA SƯ</p>
          <ArrowRightOutlined />
        </Link>
      </div>
    </div>
  );
}

export default memo(HomeTutor);
