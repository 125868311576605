export const moneyFormat = (money) =>
  money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const trimString = (string) => string.replace(/\s+/g, " ").trim();

export const getMultipleRandom = (arr, numberItemOfArr) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, numberItemOfArr);
};

export const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const scrollToTop = () => {
  const body = document.querySelector("#root");
  body.scrollIntoView(
    {
      behavior: "smooth",
    },
    500
  );
};
