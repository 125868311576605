import Swal from "sweetalert2";
import { setDoc, doc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";

import * as types from "./actionTypes";
import { database } from "~/firebase";
import { TUTORS } from "~/utils/constants/constants";

const tutorsCollectionRef = (sdt) => doc(database, "tutors", sdt);
const studentsCollectionRef = (sdt) => doc(database, "students", sdt);
const studentShortFormRef = (sdt) => doc(database, "studentShortForm", sdt);

export const registerStart = () => ({
  type: types.REGISTER_START,
});

const registerSuccess = (user) => ({
  type: types.REGISTER_SUCCESS,
  payload: user,
});

export const registerFail = (error) => ({
  type: types.REGISTER_FAIL,
  payload: error,
});

export const signUpAction = (formData, role) => {
  return async (dispatch) => {
    try {
      if (role === TUTORS) {
        await setDoc(tutorsCollectionRef(formData.sdt), formData);
      } else {
        await setDoc(studentsCollectionRef(formData.sdt), formData);
      }

      dispatch(registerSuccess(formData));
      Swal.fire(
        "Đăng ký thành công!",
        `${role === TUTORS ? "Chúc bạn dạy tốt!" : "Chúc bạn học tốt!"} `,
        "success"
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        dispatch(registerFail(error.code));
        Swal.fire({
          title: "Oops...",
          icon: "error",
          html:
            `${error.code}` +
            "<br/>" +
            "Bạn vui lòng liên hệ " +
            '<a href="https://m.me/giasumantiep">trung tâm</a>' +
            " xin cảm ơn!",
        });
        console.log("errorFirebase", error);
      }
    }
  };
};

export const signUpActionShortForm = (formData) => {
  return async (dispatch) => {
    dispatch(registerStart());
    try {
      await setDoc(studentShortFormRef(formData.sdt), formData);

      dispatch(registerSuccess(formData));
      Swal.fire("Đăng ký thành công!", "Chúc bạn học tốt!", "success");
    } catch (error) {
      if (error instanceof FirebaseError) {
        dispatch(registerFail(error.code));
        Swal.fire({
          title: "Oops...",
          icon: "error",
          html:
            `${error.code}` +
            "<br/>" +
            "Bạn vui lòng liên hệ " +
            '<a href="https://m.me/giasumantiep">trung tâm</a>' +
            " xin cảm ơn!",
        });
        console.log("errorFirebase", error);
      }
    }
  };
};
