import * as types from "./types";

export const getListFilter = () => {
  return {
    type: types.GET_LIST_FILTER,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_FILTER,
  };
};

export const maLopFilterChange = (text) => {
  return {
    type: types.FILTER_MA_LOP,
    payload: text,
  };
};

export const monHocFilterChange = (text) => {
  return {
    type: types.FILTER_MON_HOC,
    payload: text,
  };
};

export const lopHocFilterChange = (text) => {
  return {
    type: types.FILTER_LOP_HOC,
    payload: text,
  };
};

export const gioiTinhFilterChange = (text) => {
  return {
    type: types.FILTER_GIOI_TINH,
    payload: text,
  };
};

export const trinhDoFilterChange = (text) => {
  return {
    type: types.FILTER_TRINH_DO,
    payload: text,
  };
};

export const tinhThanhFilterChange = (text) => {
  return {
    type: types.FILTER_TINH_THANH,
    payload: text,
  };
};

export const quanHuyenFilterChange = (text) => {
  return {
    type: types.FILTER_QUAN_HUYEN,
    payload: text,
  };
};
