import { useEffect } from "react";
import { trapFocus } from "~/functions/trapFocus";

export const useTrapFocus = (containerId) => {
  useEffect(() => {
    const cleanUpFunction = trapFocus(containerId);

    return cleanUpFunction;
  }, [containerId]);
};
