import "./styles.scss";

const LoadingDot = () => {
  return (
    <div className="loading_container">
      <div className="loading-content">
        {/* <div className="lds-hourglass"></div> */}
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="status">
          Đang tải<span className="status__dot">.</span>
          <span className="status__dot">.</span>
          <span className="status__dot">.</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingDot;
