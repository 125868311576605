import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

import {
  TEL_NUMBER_1,
  TEL_NUMBER_2,
  TEL_NUMBER_SPACE_1,
  TEL_NUMBER_SPACE_2,
} from "~/utils/constants/constants";
import { moneyFormat } from "~/utils/functions/common";
import { takeClassAction } from "~/pages/Tutors/redux/actions";
import { loginTutor } from "~/pages/Tutors/redux/selectors";

import "./styles.scss";
import { loginAction } from "~/pages/SignIn/redux/actions";
import Swal from "sweetalert2";
import { SIGN_IN } from "~/utils/constants/pathUrl";

const Student = ({ student }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    phanTramPhi,
    maLop,
    monHoc,
    lopHoc,
    diaChi,
    phuongXa,
    quanHuyen,
    tinhTP,
    hocPhi,
    soBuoi,
    soPhut,
    daGiao,
    thoiGian,
    thongTin,
    yeuCau,
  } = student;

  const tenDuong = diaChi?.slice(diaChi?.toLowerCase().indexOf("đường"));
  const tutorLogin = useSelector(loginTutor);
  const isDisableButton = tutorLogin?.lopDangKy.includes(maLop);

  const handdleTakeClass = () => {
    if (tutorLogin) {
      const { sdt, lopDangKy } = tutorLogin;
      const dataUpdate = {
        lopDangKy: [...lopDangKy, maLop],
      };
      dispatch(takeClassAction(sdt, dataUpdate));
      dispatch(loginAction(sdt));
    } else {
      Swal.fire({
        title: "Vui lòng đăng nhập trước khi đặt lớp!",
        icon: "question",
        confirmButtonText: "Đăng nhập",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/${SIGN_IN}`);
        }
      });
    }
  };

  return (
    <div className={`search-result ${daGiao ? "bg-pink" : ""}`}>
      <div className="flex justify-between items-center">
        <div className="flex">
          <Tooltip placement="top" title={"Mã lớp"} color={"#10b981"}>
            <p className="text-white rounded-3xl bg-green-500 px-3 py-1 mr-2">
              {maLop}
            </p>
          </Tooltip>

          <Tooltip placement="top" title={"Phí giới thiệu"} color={"#f97316"}>
            <p className="text-white rounded-full bg-orange-500 px-3 py-1">
              {phanTramPhi}%
            </p>
          </Tooltip>
        </div>
        <p className="font-semibold">Lớp {daGiao ? "đã" : "chưa"} giao</p>
      </div>

      <div className="description">
        <p>
          <span className="font-semibold">Môn dạy: </span>
          <span className="text-blue-900 font-bold">{monHoc}</span>
        </p>
        <p>
          <span className="font-semibold">Lớp dạy: </span>
          <span className="text-blue-900 font-bold">{lopHoc}</span>
        </p>
        <p>
          <span className="font-semibold">Địa chỉ:</span> {tenDuong}, {phuongXa}
          , {quanHuyen}, {tinhTP}
        </p>
        <p>
          <span className="font-semibold">Mức lương: </span>
          <span className="text-red-500 font-bold">
            {moneyFormat(hocPhi)} đồng/tháng
          </span>
        </p>
        <p>
          <span className="font-semibold">Số buổi:</span> {soBuoi} buổi/tuần –
          Dạy {soPhut} phút/buổi
        </p>
        <p>
          <span className="font-semibold">Thời gian:</span> {thoiGian}
        </p>
        <p>
          <span className="font-semibold">Thông tin:</span> {thongTin}
        </p>
        <p>
          <span className="font-semibold">Yêu cầu:</span> {yeuCau}
        </p>
        <p>
          <span className="font-semibold">Liên hệ:</span>{" "}
          <a className="text-red-500" href={`tel:${TEL_NUMBER_1}`}>
            {TEL_NUMBER_SPACE_1}
          </a>{" "}
          -{" "}
          <a className="text-red-500" href={`tel:${TEL_NUMBER_2}`}>
            {TEL_NUMBER_SPACE_2}
          </a>
        </p>
      </div>

      <div className="text-center">
        <button
          className={`w-1/2 p-1.5 
                           ${
                             daGiao || isDisableButton
                               ? "text-black bg-gray-200 border-gray-200 cursor-not-allowed"
                               : "text-white bg-blue-900 hover:bg-orange-600 hover:border-orange-500"
                           } 
                              
                          `}
          onClick={handdleTakeClass}
          disabled={daGiao || isDisableButton}
        >
          Nhận lớp
        </button>
      </div>
    </div>
  );
};

export default Student;
