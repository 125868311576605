import { doc, getDoc } from "firebase/firestore";

import * as types from "./actionTypes";
import { database } from "~/firebase";

const tutorRef = (sdt) => doc(database, "tutors", sdt);

export const loginStart = () => ({
  type: types.LOGIN_START,
});

const loginSuccess = (user) => ({
  type: types.LOGIN_SUCCESS,
  payload: user,
});

export const loginFail = (error) => ({
  type: types.LOGIN_FAIL,
  payload: error,
});

export const loginAction = (sdt) => {
  return async (dispatch) => {
    dispatch(loginStart());

    try {
      const tutor = (await getDoc(tutorRef(sdt))).data();

      dispatch(loginSuccess(tutor));
    } catch (error) {
      dispatch(loginFail(error));
      console.log(error);
    }
  };
};
// export const loginAction = (user) => {
//   return async (dispatch) => {
//     dispatch(loginStart());
//     localStorage.setItem("sdt", user.sdt);
//     try {
//       dispatch(loginSuccess(user));
//     } catch (error) {
//       dispatch(loginFail(error));
//       console.log(error);
//     }
//   };
// };

export const logout = () => ({
  type: types.LOGOUT,
});
