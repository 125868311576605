import { useState } from "react";
import { Modal } from "antd";

import ShortForm from "~/pages/SignUp/SignUpStudents/components/ShortForm";

const ButtonContactTutor = ({ tutor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleContactCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button onClick={showModal}>Liên hệ</button>

      <Modal open={isModalOpen} onCancel={handleContactCancel} footer={null}>
        <ShortForm tutor={tutor} />
      </Modal>
    </>
  );
};

export default ButtonContactTutor;
