export const TITLE = "Gia sư Mẫn Tiệp";
export const TEL_NUMBER_1 = "0948774842";
export const TEL_NUMBER_SPACE_1 = "0948 774 842";
export const TEL_NUMBER_2 = "0979456501";
export const TEL_NUMBER_SPACE_2 = "0979 456 501";
export const EMAIL = "giasumantiep@gmail.com";
export const LOCATION =
  "Số 182 Lã Xuân Oai, Phường Tăng Nhơn Phú A, Thành phố Thủ Đức, Thành phố Hồ Chí Minh";

export const TUTORS = "TUTORS";
export const STUDENTS = "STUDENTS";
