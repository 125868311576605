import { lazy } from "react";

import {
  CONTACT,
  FIND_TUTOR,
  NEW_CLASS,
  TUTOR_INFO,
  SIGN_IN,
  SIGN_UP,
  STUDENT,
  TUITION,
  TUTOR,
  TUTORING_SERVICE,
  EDIT_FROFILE,
  INSTRUCTIONS,
  CONTRACT,
  FORGOT_PASSWORD,
} from "~/utils/constants/pathUrl";
import Contact from "~/pages/Contact";
import Home from "~/pages/Home";
import PageNotFound from "~/pages/PageNotFound";
import SignIn from "~/pages/SignIn";
import SignUpUser from "~/pages/SignUp/SignUpUser";
import SignUpStudents from "~/pages/SignUp/SignUpStudents";
import SignUpTutors from "~/pages/SignUp/SignUpTutors";
import FindTutors from "~/pages/Students/components/FindTutors";
import Tuition from "~/pages/Students/components/Tuition";
import TutoringService from "~/pages/Students/components/TutoringService";
import NewClass from "~/pages/Tutors/components/NewClass";
import TutorInfo from "~/pages/Tutors/components/TutorInfo";
import EditProfile from "~/pages/Tutors/components/EditProfile";
import Instructions from "~/pages/Tutors/components/Instructions";
import Contract from "~/pages/Tutors/components/ContractTutor";

const HomeTemplateLazy = lazy(() => import("~/templates/HomeTemplates"));
// const SignUpTemplateLazy = lazy(() => import("~/templates/SignUpTemplates"));
// const SignInTemplateLazy = lazy(() => import("~/templates/SignInTemplates"));
// const UserTemplateLazy = lazy(() => import("~/templates/UserTemplates"));

const routesConfig = [
  {
    path: "",
    component: HomeTemplateLazy,
    children: [
      {
        path: "/",
        component: Home,
      },
      {
        path: "*",
        component: PageNotFound,
      },
      {
        path: CONTACT,
        component: Contact,
      },
    ],
  },
  {
    path: "",
    component: HomeTemplateLazy,
    // component: SignInTemplateLazy,
    children: [
      {
        path: SIGN_IN,
        component: SignIn,
      },
      {
        path: FORGOT_PASSWORD,
        component: SignIn,
      },
    ],
  },
  {
    path: SIGN_UP,
    component: HomeTemplateLazy,
    // component: SignUpTemplateLazy,
    children: [
      {
        path: "",
        component: SignUpUser,
      },
      {
        path: STUDENT,
        component: SignUpStudents,
      },
      {
        path: TUTOR,
        component: SignUpTutors,
      },
    ],
  },
  {
    path: TUTOR,
    component: HomeTemplateLazy,
    // component: UserTemplateLazy,
    children: [
      {
        path: NEW_CLASS,
        component: NewClass,
      },
      {
        path: TUTOR_INFO,
        component: TutorInfo,
      },
      {
        path: EDIT_FROFILE,
        component: EditProfile,
      },
      {
        path: INSTRUCTIONS,
        component: Instructions,
      },
      {
        path: CONTRACT,
        component: Contract,
      },
    ],
  },
  {
    path: STUDENT,
    component: HomeTemplateLazy,
    // component: UserTemplateLazy,
    children: [
      {
        path: FIND_TUTOR,
        component: FindTutors,
      },
      {
        path: TUITION,
        component: Tuition,
      },
      {
        path: TUTORING_SERVICE,
        component: TutoringService,
      },
    ],
  },
];

export default routesConfig;
