import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input } from "antd";
import Icon from "@ant-design/icons";
import bcrypt from "bcryptjs";
import Swal from "sweetalert2";

import Ellipse from "~/assets/svg/sign in/Ellipse.svg";
import FloatLabel from "~/components/FloatLabels/FloatLabel";
import { useFocus, useThrottle, useTrapFocus } from "~/hooks";
import { FORGOT_PASSWORD, SIGN_UP } from "~/utils/constants/pathUrl";
import { getListTutors } from "~/pages/Students/redux/actions";
import { tutorsSelector } from "~/pages/Students/redux/selectors";
import { loginAction } from "~/pages/SignIn/redux/actions";

const LoginSvg = () => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M19.845 13.28L12.27 18.965V27.38C12.27 28.82 14.01 29.54 15.03 28.52L22.8 20.75C24.045 19.505 24.045 17.48 22.8 16.235L19.845 13.28Z"
      fill="white"
    />
    <path
      d="M12.27 9.62001V18.965L19.845 13.28L15.03 8.46501C14.01 7.46001 12.27 8.18001 12.27 9.62001Z"
      fill="white"
    />
  </svg>
);
const LoginIcon = (props) => <Icon component={LoginSvg} {...props} />;

const Login = () => {
  const [user, setUser] = useState({
    sdt: "",
    matKhau: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getListTutors());

    // eslint-disable-next-line
  }, []);

  const [form] = Form.useForm();
  const tutorList = useSelector(tutorsSelector);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUser((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s+/g, " ").trim(),
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const onFinish = async (values) => {
    const tutorLogin = tutorList.find((tutor) => tutor.sdt === values.sdt);

    bcrypt.compare(user.matKhau, tutorLogin.matKhau, async (err, isMatch) => {
      if (err) {
        throw err;
      } else if (!isMatch) {
        Swal.fire(
          "Mật khẩu chưa đúng!",
          "Vui lòng nhập lại mật khẩu!",
          "error"
        );
      } else {
        localStorage.setItem("sdt", tutorLogin.sdt);
        dispatch(loginAction(tutorLogin.sdt));
        form.resetFields();
        setUser({
          sdt: null,
          matKhau: null,
        });
        await Swal.fire({
          title: "Đăng nhập thành công",
          icon: "success",
          text: "Chào mừng bạn đến với Gia sư Mẫn Tiệp!",
          confirmButtonText: "Quay về trang chủ!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    });
  };

  useFocus("sdt");
  useTrapFocus("sign-in");
  return (
    <div className="form">
      <h1>Đăng nhập</h1>

      <Form
        id="sign-in"
        form={form}
        name="login"
        onFinish={onFinish}
        scrollToFirstError
      >
        <FloatLabel label="Số điện thoại" name="sdt" value={user.sdt}>
          <Form.Item
            name="sdt"
            rules={[
              {
                min: 10,
                max: 10,
                pattern: new RegExp(/(0[2-9])+([0-9]{8})\b/),
                message: "Số điện thoại chưa đúng!",
              },
              {
                required: true,
                message: "Vui lòng nhập số điện thoại!",
              },
              () => ({
                validator(_, value) {
                  const isDuplicateSdt = tutorList.some(
                    (tutor) => tutor.sdt === value
                  );
                  if (!value || value?.length !== 10 || isDuplicateSdt) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Số điện thoại chưa đăng ký!")
                  );
                },
              }),
            ]}
          >
            <Input
              id="sdt"
              value={user.sdt}
              name="sdt"
              onChange={handleInputThrottled}
              maxLength={10}
              allowClear
            />
          </Form.Item>
        </FloatLabel>

        <FloatLabel label="Mật khẩu" name="matKhau" value={user.matKhau}>
          <Form.Item
            name="matKhau"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu!",
              },
            ]}
          >
            <Input.Password
              value={user.matKhau}
              name="matKhau"
              onChange={handleInputThrottled}
              autoComplete="off"
              allowClear
            />
          </Form.Item>
        </FloatLabel>

        <div className="forgot-password">
          <Link to={`/${FORGOT_PASSWORD}`}>Quên mật khẩu?</Link>
        </div>

        <Form.Item>
          <Button className="submit-form" htmlType="submit">
            <div className="login">Đăng nhập</div>
            <LoginIcon />
          </Button>
        </Form.Item>

        <hr />

        <div className="register">
          <img src={Ellipse} alt="Ellipse" />
          <div className="text">
            Chưa có tài khoản?
            <Link to={`/${SIGN_UP}`}>Đăng ký ngay</Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
