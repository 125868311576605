import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import emailjs from "@emailjs/browser";
import { Button, Form, Input } from "antd";
import bcrypt from "bcryptjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Ellipse from "~/assets/svg/sign in/Ellipse.svg";
import FloatLabel from "~/components/FloatLabels/FloatLabel";
import Spinner from "~/components/Loading/Spinner";
import ReCapcha from "~/components/ReCapcha";
import { useFocus, useThrottle } from "~/hooks";
import { tutorsSelector } from "~/pages/Students/redux/selectors";
import {
  editProfileAction,
  editProfileStart,
} from "~/pages/Tutors/redux/actions";
import { tutorSelector } from "~/pages/Tutors/redux/selectors";
import { SIGN_IN, SIGN_UP } from "~/utils/constants/pathUrl";
import { generateRandomString, trimString } from "~/utils/functions/common";
import { validateEmail } from "~/utils/validations/validationsInput";

const ForgotPassword = () => {
  const [account, setAccount] = useState({
    sdt: "",
    email: "",
  });
  const [isCheckRecaptcha, setIsCheckRecaptcha] = useState(undefined);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const tutorList = useSelector(tutorsSelector);
  const user = useSelector(tutorSelector);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const val = trimString(value);
    setAccount((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  const handleInputThrottled = useThrottle(handleInputChange, 1000);

  const handleCheckRecaptcha = (value) => {
    setIsCheckRecaptcha(true);
  };

  const onFinish = (values) => {
    if (isCheckRecaptcha) {
      dispatch(editProfileStart());

      if (user?.error) {
        return;
      } else {
        form.resetFields();
      }

      const password = generateRandomString(20);
      const hashedPassword = bcrypt.hashSync(password, 10);
      const userUpdate = { ...values, matKhau: hashedPassword };
      const templateParams = {
        sdt: values.sdt,
        to_email: values.email,
        password,
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (response) => {
            dispatch(editProfileAction(userUpdate));
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    } else {
      setIsCheckRecaptcha(false);
    }
  };

  useFocus("sdt");

  return (
    <div className="form">
      <h1>Khôi phục mật khẩu</h1>
      <div className="mb-5">
        <div className="flex items-center">
          <WarningTwoTone twoToneColor="red" />
          <p className="text-lg font-medium ml-2">
            Mật khẩu mới sẽ được gửi vào email của bạn!
          </p>
        </div>
        <p>
          Nếu không có ở <strong>Hộp thư đến (Inbox)</strong>, bạn hãy vào mục{" "}
          <strong>Thư rác (Spam)</strong>.
          <br />
          Mở email và hãy nhấp vào <strong>Không phải thư rác</strong>.
          <br />
          Khi đó, email sẽ nằm trong <strong>Hộp thư đến (Inbox)</strong> của
          bạn.
        </p>
      </div>

      <Form
        form={form}
        name="forgot-password"
        onFinish={onFinish}
        scrollToFirstError
      >
        <FloatLabel label="Số điện thoại" name="sdt" value={account.sdt}>
          <Form.Item
            name="sdt"
            rules={[
              {
                min: 10,
                max: 10,
                pattern: new RegExp(/(0[2-9])+([0-9]{8})\b/),
                message: "Số điện thoại chưa đúng!",
              },
              {
                required: true,
                message: "Vui lòng nhập số điện thoại!",
              },
              () => ({
                validator(_, value) {
                  const isDuplicateSdt = tutorList.some(
                    (tutor) => tutor.sdt === value
                  );
                  if (!value || value?.length !== 10 || isDuplicateSdt) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Số điện thoại chưa đăng ký!")
                  );
                },
              }),
            ]}
          >
            <Input
              id="sdt"
              name="sdt"
              value={account.sdt}
              onChange={handleInputThrottled}
              maxLength={10}
              allowClear
            />
          </Form.Item>
        </FloatLabel>

        <FloatLabel label="Email" name="email" value={account.email}>
          <Form.Item name="email" rules={validateEmail}>
            <Input
              placeholder={account.email}
              value={account.email}
              name="email"
              onChange={handleInputThrottled}
              allowClear
            />
          </Form.Item>
        </FloatLabel>

        <ReCapcha
          isCheckRecaptcha={isCheckRecaptcha}
          handleCheckRecaptcha={handleCheckRecaptcha}
        />

        <div className="forgot-password">
          <Link to={`/${SIGN_IN}`}>Trở về đăng nhập</Link>
        </div>

        <Form.Item>
          <Button className="submit-form" htmlType="submit">
            <div className="login">Cài lại mật khẩu</div>
            <CheckCircleTwoTone
              style={{ fontSize: "30px" }}
              twoToneColor="#6e8ebd"
            />
          </Button>
        </Form.Item>

        <hr />

        <div className="register">
          <img src={Ellipse} alt="Ellipse" />
          <div className="text">
            Chưa có tài khoản?
            <Link to={`/${SIGN_UP}`}>Đăng ký ngay</Link>
          </div>
        </div>
      </Form>

      {user?.loading && <Spinner />}
    </div>
  );
};

export default ForgotPassword;
