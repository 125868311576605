import axios from "axios";

export const uploadImagesToCloudinary = async (
  imagesFileList,
  nguoiDung,
  kieuAnh,
  sdt
  // hoTen
) => {
  const uploaders = imagesFileList.map(async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    formData.append("folder", `giaSuManTiep/${nguoiDung}/${kieuAnh}/${sdt}`);
    // formData.append("public_id", `${kieuAnh}_${hoTen}`);

    return axios
      .post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        formData,
        {
          headers: { "X-Requested-With": "XMLHttpRequest" },
        }
      )
      .then((response) => {
        return response.data.secure_url;
      });
  });

  // Once all the files are uploaded
  return axios.all(uploaders).then((values) => {
    return values;
  });
};
