import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAZswvR77LRI3Y1zKDslsSVEPYK7vCkaDI",
  authDomain: "gia-su-man-tiep.firebaseapp.com",
  databaseURL:
    "https://gia-su-man-tiep-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gia-su-man-tiep",
  storageBucket: "gia-su-man-tiep.appspot.com",
  messagingSenderId: "174483186821",
  appId: "1:174483186821:web:68b3f65cd8a4dc897bf5ec",
  measurementId: "G-8KGWYPSYQ9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const database = getFirestore(app);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export { database, auth, googleAuthProvider, facebookAuthProvider };
