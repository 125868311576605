import "./styles.scss";

const Contract = () => {
  return (
    <div className="mt-32">
      <h3>Đang cập nhật...</h3>
    </div>
  );
};

export default Contract;
