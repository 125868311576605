import * as types from "./types";

const initState = {
  maLop: undefined,
  monHoc: undefined,
  lopHoc: undefined,
  gioiTinh: [],
  trinhDo: [],
  tinhThanh: undefined,
  quanHuyen: undefined,
};

const filtersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_LIST_FILTER:
      return { ...state };

    case types.CLEAR_FILTER:
      return initState;

    case types.FILTER_MA_LOP:
      return {
        ...state,
        maLop: action.payload,
      };

    case types.FILTER_MON_HOC:
      return {
        ...state,
        monHoc: action.payload,
      };

    case types.FILTER_LOP_HOC:
      return {
        ...state,
        lopHoc: action.payload,
      };

    case types.FILTER_GIOI_TINH:
      return {
        ...state,
        gioiTinh: action.payload,
      };

    case types.FILTER_TRINH_DO:
      return {
        ...state,
        trinhDo: action.payload,
      };

    case types.FILTER_TINH_THANH:
      return {
        ...state,
        tinhThanh: action.payload,
      };

    case types.FILTER_QUAN_HUYEN:
      return {
        ...state,
        quanHuyen: action.payload,
      };

    default:
      return state;
  }
};

export default filtersReducer;
