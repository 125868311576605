import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { BackTop } from "antd";

import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import routesConfig from "~/routes/routesConfig";
import LoadingDot from "~/components/Loading/LoadingDot";
import ContactIcon from "~/components/ContactIcon";
import { scrollToTop } from "./utils/functions/common";

const App = () => {
  const renderRoutes = () => {
    return routesConfig.map((route) => (
      <Route key={route.path} path={route.path} element={<route.component />}>
        {route.children?.map((child) => (
          <Route
            key={child.path}
            path={child.path}
            element={<child.component />}
          />
        ))}
      </Route>
    ));
  };

  return (
    <Suspense fallback={<LoadingDot />}>
      <BrowserRouter>
        <AutoScrollToTop />
        <ContactIcon />
        <BackTop />
        <Routes>{renderRoutes()}</Routes>
      </BrowserRouter>
    </Suspense>
  );
};

const AutoScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
};

export default App;
